import { useEffect } from 'react';

const usePinterestPixel = () => {
    const PINTEREST_TID = '2613851358764';  
    
    useEffect(() => {
        // Check if Pinterest pixel is already initialized
        if (window.pintrk && window.pintrk.queue) {
            return;
        }

        // Initialize the pintrk
        window.pintrk = function() {
            window.pintrk.queue.push(Array.prototype.slice.call(arguments));
        };
        window.pintrk.queue = [];
        window.pintrk.version = "3.0";

        // Add the Pinterest script to the DOM
        const pinterestScript = document.createElement('script');
        pinterestScript.async = true;
        pinterestScript.src = "https://s.pinimg.com/ct/core.js";
        document.head.appendChild(pinterestScript);

        pinterestScript.onload = () => {
            window.pintrk('load', PINTEREST_TID);
            window.pintrk('page');
        };

        // Add the Pinterest noscript image to the DOM
        const pinterestNoScript = document.createElement('noscript');
        const img = document.createElement('img');
        img.height = 1;
        img.width = 1;
        img.style.display = 'none';
        img.alt = "";
        img.src = `https://ct.pinterest.com/v3/?event=init&tid=${PINTEREST_TID}&noscript=1`;
        pinterestNoScript.appendChild(img);
        document.body.appendChild(pinterestNoScript);

    }, []);

};

export default usePinterestPixel;
