import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

export default function LayoutsPage() {
  return (
    <div>
      <Router>
        <div>
          <nav>
            <ul>
              <li>
                <a href="/home">Homepage Layout</a>
              </li>
              <li>
                <a href="/login">Sign In Layout</a>
              </li>
              <li>
                <i>
                  <b>Informational Layouts</b>
                </i>
              </li>
              <ul>
                <li>
                  <a href="/about">About Us</a>
                </li>
                <li>
                  <a href="/blog">Blog Layout</a>
                </li>
                <li>
                  <a href="/contact">Contact Us Layout</a>
                </li>
                <li>
                  <a href="/faq">FAQ Layout</a>
                </li>
                <li>
                  <a href="/howitworks">How It Works (Seller Support) Layout</a>
                </li>
                <li>
                  <a href="/impact">Our Impact</a>
                </li>
                <li>
                  <a href="/partnership">Partnership Layout</a>
                </li>
                <li>
                  <a href="/press">Press Layout</a>
                </li>
                <li>
                  <a href="/shop/category/PurposerGiftCards">
                    Purposer Gift Cards Layout
                  </a>
                </li>
                <li>
                  <a href="/faq/seller">Seller FAQ Layout</a>
                </li>
                <li>
                  <a href="/support/seller">Seller Support Layout</a>
                </li>
                <li>
                  <a href="/faq/shippingandreturns">Shipping & Returns FAQ</a>
                </li>
              </ul>
              <li>
                <i>
                  <b>Master Layouts</b>
                </i>
              </li>
              <ul>
                <li>
                  <a href="/debug/layouts/masterLayout">Master Layout</a>
                </li>
                <li>
                  <a href="/debug/layouts/managementmasterLayout">
                    Management Master Layout
                  </a>
                </li>
              </ul>
              <li>
                <i>
                  <b>Seller Layouts</b>
                </i>
              </li>
              <ul>
                <li>
                  <a href="/shop/seller/1/admin">Admin Seller Store Layout</a>
                </li>
                <li>
                  <a href="/seller/1/orders">Seller Dashboard Layout</a>
                </li>
                <li>
                  <a href="/seller/signup">Seller Sign Up Layout</a>
                </li>
              </ul>
              <li>
                <i>
                  <b>Shopper Layouts</b>
                </i>
              </li>
              <ul>
                <li>
                  <a href="/cart">Cart Layout</a>
                </li>
                <li>
                  <a href="/shop/category">Shop Category Layout</a>
                </li>
                <li>
                  <a href="/acct">My Account Layout</a>
                </li>
                <li>
                  <a href="/shop/product/test">Product Layout</a>
                </li>
                <li>
                  <a href="/shop/purpose">Purpose Layout</a>
                </li>
                <li>
                  <a href="/shop/seller/1">Seller Store Layout</a>
                </li>
              </ul>
            </ul>
          </nav>
        </div>
      </Router>
    </div>
  );
}
