import { Container, Typography, Grid } from "@mui/material";
import FavoriteSharpIcon from "@mui/icons-material/FavoriteSharp";
import { styled } from "@mui/material/styles";
import { useRef } from "react";
import useElementWidth from "../hooks/useElementWidth";

const HeaderContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const Heart = styled(FavoriteSharpIcon)({
  color: "var(--coral)",
  fontSize: 60,
  marginLeft: 5,
  marginRight: 5,
});

const SubHeader = styled(Typography)({
  marginTop: 10,
  marginBottom: 40,
});

const IGImage = styled("img")({
  width: "100%",
  borderRadius: 25,
  objectFit: "cover",
});

function LoveInstagram() {
  const gridItemRef = useRef(null);
  const heightRatio = 0.8;
  const gridItemWidth = useElementWidth(gridItemRef, false);

  return (
    <Container maxWidth="xl">
      <HeaderContainer>
        <Typography display="inline" variant="h4" className={"roboto-bold"}>
          We
        </Typography>
        <Heart />
        <Typography display="inline" variant="h4" className={"roboto-bold"}>
          Instagram
        </Typography>
      </HeaderContainer>
      <SubHeader align="center" variant="h5" className={"roboto-bold"}>
        Shop Your Favorite Items @BeAPurposer
      </SubHeader>
      <Grid
        container
        columns={4}
        columnSpacing={{ lg: 14, md: 10, sm: 8, xs: 0 }}
        rowSpacing={{ sm: 5, xs: 5 }}
      >
        <Grid item xs={4} sm={2} md={1} ref={gridItemRef}>
          <IGImage
            src={
              "https://d2eudfgh8kpk8j.cloudfront.net/common/thumbs/9c6420bc-21e5-462f-a48f-8f2642f34847_thumb.jpg"
            }
            alt="ig_image_1"
            sx={{
              height: {
                lg: (gridItemWidth - 112) / heightRatio,
                sm: (gridItemWidth - 80) / heightRatio,
              },
            }}
          />
        </Grid>
        <Grid item xs={4} sm={2} md={1}>
          <IGImage
            src={
              "https://d2eudfgh8kpk8j.cloudfront.net/common/thumbs/b389570b-de02-4d18-b08f-897a69ece541_thumb.jpg"
            }
            alt="ig_image_2"
            sx={{
              height: {
                lg: (gridItemWidth - 112) / heightRatio,
                sm: (gridItemWidth - 80) / heightRatio,
              },
            }}
          />
        </Grid>
        <Grid item xs={4} sm={2} md={1}>
          <IGImage
            src={
              "https://d2eudfgh8kpk8j.cloudfront.net/common/thumbs/1416ebd2-b0b9-4e73-8798-40caba4e961d_thumb.jpg"
            }
            alt="ig_image_3"
            sx={{
              height: {
                lg: (gridItemWidth - 112) / heightRatio,
                sm: (gridItemWidth - 80) / heightRatio,
              },
            }}
          />
        </Grid>
        <Grid item xs={4} sm={2} md={1}>
          <IGImage
            src={
              "https://d2eudfgh8kpk8j.cloudfront.net/common/thumbs/170f447f-2cd9-4563-a12d-14bdf4932d96_thumb.jpg"
            }
            alt="ig_image_4"
            sx={{
              height: {
                lg: (gridItemWidth - 112) / heightRatio,
                sm: (gridItemWidth - 80) / heightRatio,
              },
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default LoveInstagram;
