import { useEffect } from 'react';


const useGoogleTagManager = () => {
  const CONTAINER_ID = 'G-PWR4E585H7';
  useEffect(() => {
    // Check if GTM is already initialized
    if (window.dataLayer && window.dataLayer.find((o) => o['gtm.start'])) {
      return;
    }

    // Initialize the dataLayer
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });

    // Add the GTM script to the DOM
    const gtmScript = document.createElement('script');
    gtmScript.async = true;
    gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${CONTAINER_ID}`;
    document.head.appendChild(gtmScript);

    // Add the GTM noscript iframe to the DOM
    const gtmNoScript = document.createElement('noscript');
    const iframe = document.createElement('iframe');
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${CONTAINER_ID}`;
    iframe.height = 0;
    iframe.width = 0;
    iframe.style.display = 'none';
    iframe.style.visibility = 'hidden';
    gtmNoScript.appendChild(iframe);
    document.body.appendChild(gtmNoScript);
  }, [CONTAINER_ID]);
};

export default useGoogleTagManager;
