import { Auth } from "aws-amplify";
import { PRODUCT_SIZES, PRODUCT_SIZE_TYPE, PRODUCT_LENGTH_UNITS, PRODUCT_WEIGHT_UNITS, PURPOSER_ADMIN_USER_IDS } from "./constants";

export function tryParseJSONObject(jsonString) {
  try {
    var o = JSON.parse(jsonString);

    // Handle non-exception-throwing cases:
    // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
    // but... JSON.parse(null) returns null, and typeof null === "object", 
    // so we must check for that, too. Thankfully, null is falsey, so this suffices:
    if (o && typeof o === "object") {
      return o;
    }
  }
  catch (e) { }

  return false;
};

export async function getSessionJwt() {
  try {
    const currSession = await Auth.currentSession();
    return currSession.idToken.jwtToken;
  } catch {
    console.log("Not Signed in.");
    return undefined;
  }
};

export async function getUserIdFromAuth(callback) {
  Auth.currentAuthenticatedUser().then((user) => {
    callback(user?.attributes?.sub);
    return;
  },
  (error) => {
    callback(null);
    return;
  },
  {});
  // callback(null);
  // return;
}

export async function getUserIdFromAuthAsync() {
  const result = await Auth.currentAuthenticatedUser();
  return result?.attributes?.sub;
}

export function getNumberOfMinsSinceDate(inputDate) {
  const dateNow = Date.now();

  // One minute in milliseconds
  const oneMin = 1000 * 60;

  // Calculating the time difference between two dates
  const diffInTime = dateNow - inputDate;

  // Calculating the number of minutes between two dates
  const diffInMins = Math.round(diffInTime / oneMin);

  return diffInMins;
}

//Function to get a cached item if it is in json format and not expired
export function getJsonCache(dataName, minsExpireCache = 10) {
  try {
    const cachedData = localStorage.getItem(dataName) ? JSON.parse(localStorage.getItem(dataName)) : null;
    if (cachedData && cachedData?.data && cachedData?.timestamp && getNumberOfMinsSinceDate(cachedData?.timestamp) < minsExpireCache) {
      return cachedData?.data;
    }
  } catch (e) {
    console.log(`Error getting ${dataName} cache: ${e.message}`);
  }
  return null;
}

//Function to set a cached item in json format and the timestamp it was called
export function setJsonCache(dataName, data) {
  try {
    localStorage.setItem(dataName, JSON.stringify({
      data: data,
      timestamp: Date.now()
    }));
  } catch (e) {
    console.log(`Error setting ${dataName} cache: ${e.message}`);
  }
  return null;
}

export function round(value, precision) {
  if (value === null || value === undefined) return value;
  const numberValue = new Number(value);
  var multiplier = Math.pow(10, precision || 0);
  const roundedNumber = Math.round(numberValue * multiplier) / multiplier;
  const roundedNumberWith2Decimals = roundedNumber?.toFixed(2);
  return roundedNumberWith2Decimals;
}

export function shortenUUID(orderID) {
  const newOrderIDSplit = orderID?.split('-')
  if (newOrderIDSplit && newOrderIDSplit.length > 0) {
    return newOrderIDSplit[0];
  }
  return orderID;
}

export function formatDate(date) {
  if (!date) return '';
  const dateOptions = { year: "numeric", month: "short", day: "numeric" };
  const formattedDate = new Date(date).toLocaleDateString('en-us', dateOptions);
  return formattedDate;
}

export function hasDatePassed(dateString) {
  if (!dateString) return false;
  // Convert the date string to a Date object
  const date = new Date(dateString);

  // Get the current date and time
  const now = new Date();

  // Return true if the date has passed, false otherwise
  return date < now;
}

export function isValidEmail(email) {
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
}

export function isValidPhoneNumber(phoneNumber) {
  const phoneNumberRegex = /^\+1\s\(\d{3}\)\s\d{3}-\d{4}$/;
  return phoneNumberRegex.test(phoneNumber);
}

export function formatPhoneNumber(phoneNumber) {
  if (isValidPhoneNumber(phoneNumber)) {
    return phoneNumber?.replace(/[()-\s]/g, '');
  }
  return null;
}

export function isPurposerUserAdmin(userID) {
  if (userID && typeof userID === "string" && PURPOSER_ADMIN_USER_IDS.includes(userID)) {
    return true;
  } else {
    return false;
  }
}

export function hasSubstring(input, array) {
  return array.some(unit => input.includes(unit));
}

function countX(str) {
  let count = 0;
  const regex = / X /g;
  const matches = str.match(regex);
  
  if (matches !== null) {
    count = matches.length;
  }
  
  return count;
}

export function deconstructProductVariationSize(variation) {
  if (!variation.size || typeof variation.size != 'string') return;
  const sizeDimensionsCount = countX(variation.size);
  if (PRODUCT_SIZES.includes(variation.size)) {
    variation.sizeType = PRODUCT_SIZE_TYPE.STANDARD
  } else if (variation.size.startsWith('Set of items: ')) {
    variation.size = variation.size.split('Set of items: ')?.[1]
    variation.sizeType = PRODUCT_SIZE_TYPE.SET
  } else if (variation.size.startsWith('Custom: ')) {
    variation.size = variation.size.split('Custom: ')?.[1]
    variation.sizeType = PRODUCT_SIZE_TYPE.CUSTOM;
  } else if (sizeDimensionsCount === 1 && hasSubstring(variation.size, PRODUCT_LENGTH_UNITS)) {
    variation.sizeType = PRODUCT_SIZE_TYPE.DIMENSIONS_L_W
    const sizeSplit = variation.size.split(' X ')
    const size1 = sizeSplit?.[0]
    const size2AndUnitSplit = sizeSplit?.[1].split(' ');
    const size2 = size2AndUnitSplit?.[0]
    const sizeUnit = size2AndUnitSplit?.[1]

    variation.size1 = size1;
    variation.size2 = size2;
    variation.sizeUnit = sizeUnit;
  } else if (sizeDimensionsCount === 2 && hasSubstring(variation.size, PRODUCT_LENGTH_UNITS)) {
    variation.sizeType = PRODUCT_SIZE_TYPE.DIMENSIONS_L_W_H
    const sizeSplit = variation.size.split(' X ')
    const size1 = sizeSplit?.[0]
    const size2AndUnitSplit = sizeSplit?.[1].split(' ');
    const size2 = size2AndUnitSplit?.[0]
    const size3AndUnitSplit = sizeSplit?.[2].split(' ');
    const size3 = size3AndUnitSplit?.[0]
    const sizeUnit = size3AndUnitSplit?.[1]

    variation.size1 = size1;
    variation.size2 = size2;
    variation.size3 = size3;
    variation.sizeUnit = sizeUnit;
  } else if (hasSubstring(variation.size, PRODUCT_LENGTH_UNITS)) {
    const sizeSplit = variation.size.split(' ')
    variation.size = sizeSplit?.[0]
    variation.sizeUnit = sizeSplit?.[1]
    variation.sizeType = PRODUCT_SIZE_TYPE.LENGTH_OR_WIDTH
  } else if (hasSubstring(variation.size, PRODUCT_WEIGHT_UNITS)) {
    if (variation.size.includes('lb') && variation.size.includes('oz')) {
      const sizeSplit = variation.size.split(' lb ')
      variation.size1 = sizeSplit?.[0]
      variation.size2 = sizeSplit?.[1].split(' oz')?.[0]
    } else if (variation.size.includes('lb')) {
      const sizeSplit = variation.size.split(' lb')
      variation.size1 = sizeSplit?.[0]
      variation.size2 = '0'
    } else if (variation.size.includes('oz')) {
      const sizeSplit = variation.size.split(' oz')
      variation.size1 = '0'
      variation.size2 = sizeSplit?.[0]
    }
    variation.sizeType = PRODUCT_SIZE_TYPE.WEIGHT
  } else {
    console.log(`Couldn't parse the sizeType of this variation!`)
  }
  return;
}

export function constructProductVariationSize(variation) {
  if (!variation.sizeType) return;

  if (variation.sizeType === PRODUCT_SIZE_TYPE.STANDARD) {
    //Size is already appropriate.
    if (!variation.size && variation.size != 0) {
      variation.size = null;
      return;
    }
  }
  else if (variation.sizeType === PRODUCT_SIZE_TYPE.SET) {
    if (!variation.size) {
      variation.size = null;
      return;
    }
    variation.size = 'Set of items: ' + variation.size;
  } else if (variation.sizeType === PRODUCT_SIZE_TYPE.CUSTOM) {
    if (!variation.size) {
      variation.size = null;
      return;
    }
    variation.size = 'Custom: ' + variation.size;
  } else if (variation.sizeType === PRODUCT_SIZE_TYPE.DIMENSIONS_L_W) {
    if (!variation.size1 || !variation.size2 || !variation.sizeUnit) {
      variation.size = null;
      return;
    }
    variation.size = variation.size1 + ' X ' + variation.size2 + ' ' + variation.sizeUnit;
  } else if (variation.sizeType === PRODUCT_SIZE_TYPE.DIMENSIONS_L_W_H) {
    if (!variation.size1 || !variation.size2 || !variation.size3 || !variation.sizeUnit) {
      variation.size = null;
      return;
    }
    variation.size = variation.size1 + ' X ' + variation.size2 + ' X ' + variation.size3 + ' ' + variation.sizeUnit;
  } else if (variation.sizeType === PRODUCT_SIZE_TYPE.LENGTH_OR_WIDTH) {
    if (!variation.size || !variation.sizeUnit) {
      variation.size = null;
      return;
    }
    variation.size = variation.size + ' ' + variation.sizeUnit;
  } else if (variation.sizeType === PRODUCT_SIZE_TYPE.WEIGHT) {
    if (!Number(variation.size1)) {
      variation.size1 = 0;
    }
    if (!Number(variation.size2)) {
      variation.size2 = 0;
    }
    if (!variation.size1 && !variation.size2) {
      variation.size = null;
      return;
    } else if (!variation.size1) { //if no lbs, then it's just oz
      variation.size = variation.size2 + ' oz';
    } else if (!variation.size2) { //if no oz, then it's just lbs
      variation.size = variation.size1 + ' lb';
    } else {
      variation.size = variation.size1 + ' lb ' + variation.size2 + ' oz';
    }
  }
  return;
}

export function outputViewProductVariationSize(sizeString) {
  if (sizeString?.startsWith('Custom: ')) {
    return sizeString?.split('Custom: ')?.[1]
  }
  return sizeString;
}

export function getFirstOfEachMonth(startDate, endDate) {
  let result = [];
  let currentDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1);

  while (currentDate <= endDate) {
    result.push(new Date(currentDate.getTime()));
    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return result;
}

export function isSameMonth(date1, date2) {
  return date1.getMonth() === date2.getMonth();
}

export function isProd() {
  if (process.env.REACT_APP_USER_BRANCH === 'prod') {
    return true;
  }
  return false;
}

function getSizeInBytes(obj) {
  let str = null;
  if (typeof obj === 'string') {
    // If obj is a string, then use it
    str = obj;
  } else {
    // Else, make obj into a string
    str = JSON.stringify(obj);
  }
  // Get the length of the Uint8Array
  const bytes = new TextEncoder().encode(str).length;
  return bytes;
};

export function getSizeInMegaBytes(obj) {
  const bytes = getSizeInBytes(obj);
  const fileSizeMB = bytes / (1024 ** 2)
  return fileSizeMB;
};

function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export function logObjectSize(obj, objectName = 'Object') {
  const bytes = getSizeInBytes(obj);
  console.log(`${objectName} is approximately ${formatBytes(bytes)}`);
};

export function isStringABase64File(stringToCheck) {
  if (!stringToCheck || typeof stringToCheck !== 'string') return false;
  if (stringToCheck.includes(';name=') && stringToCheck.includes(';base64')) {
    return true;
  }
  return false;
}

export function getBase64FileName(base64String) {
  if (!isStringABase64File(base64String)) return null;
  return base64String?.split(';name=')?.[1]?.split(';base64')?.[0];
}

export function validateDollarAmount(amount) {
  // The regular expression checks for a number that starts with any number of digits
  // followed by an optional decimal point and exactly 2 digits.
  const regex = /^\d+(\.\d{2})?$/;

  // Use the test() function to check if the input matches the regular expression
  if (regex.test(amount)) {
    return amount;
  }

  // If the input does not match the regular expression, return null
  return null;
}

export function shuffle(array) {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

export function splitArray(array) {
  const chunkSize = 30;
  const numOfChunks = Math.ceil(array.length / chunkSize);
  const result = [];

  for (let i = 0; i < numOfChunks; i++) {
    result.push(array.slice(i * chunkSize, (i + 1) * chunkSize));
  }

  return result;
}

export function findSmallestPriceItemInArray(currObjectArray) {
  if (Array.isArray(currObjectArray)) {
    // const highest = currObjectArray.reduce((previous, current) => {
    //   return current.price > previous.price ? current : previous;
    // });
    const activeOptions = currObjectArray.filter((option) => option.active == true);
    if (activeOptions.length < 1) return null;
    const lowest = activeOptions.reduce((previous, current) => {
      return current.price < previous.price ? current : previous;
    });
    return lowest;
  }
  return null;
}

export function sortSizes(sizes) {
  //Function that tries to find the best way to sort the size/styles of variations given many formats

  let sizeObjects = sizes.map(size => {
    return { size: size, value: 0 };
  });

  let standardCount = 0;
  let lengthAndWidthCount = 0;
  let lengthAndWidthAndHeightCount = 0;
  let lengthOrWidthCount = 0;
  let weightCount = 0;
  let setCount = 0;
  //The above 'counts' don't actually do anything, might remove later
  
  for (const currSizeObj of sizeObjects) {

    const sizeDimensionsCount = countX(currSizeObj.size);
    if (PRODUCT_SIZES.includes(currSizeObj.size)) {
      standardCount++;
      currSizeObj.value = PRODUCT_SIZES.indexOf(currSizeObj.size);
      continue;
    } else if (sizeDimensionsCount === 1 && hasSubstring(currSizeObj.size, PRODUCT_LENGTH_UNITS)) {
      lengthAndWidthCount++;
      
      const sizeSplit = currSizeObj.size.split(' X ')
      const size1 = sizeSplit?.[0]
      const size2AndUnitSplit = sizeSplit?.[1].split(' ');
      const size2 = size2AndUnitSplit?.[0]
      const sizeUnit = size2AndUnitSplit?.[1]
      
      let sizeIntegerValue = size1 * size2;

      if (sizeUnit === PRODUCT_LENGTH_UNITS[1]) {
        //Centimeters already
      } else if(sizeUnit === PRODUCT_LENGTH_UNITS[2]) {
        //Foot to CM
        sizeIntegerValue *= 30.48
      } else if(sizeUnit === PRODUCT_LENGTH_UNITS[0]) {
        //Inches to CM
        sizeIntegerValue *= 2.54
      }
      currSizeObj.value = sizeIntegerValue;
    } else if (sizeDimensionsCount === 2 && hasSubstring(currSizeObj.size, PRODUCT_LENGTH_UNITS)) {
      lengthAndWidthAndHeightCount++;
      const sizeSplit = currSizeObj.size.split(' X ')
      const size1 = sizeSplit?.[0]
      const size2AndUnitSplit = sizeSplit?.[1].split(' ');
      const size2 = size2AndUnitSplit?.[0]
      const size3AndUnitSplit = sizeSplit?.[2].split(' ');
      const size3 = size3AndUnitSplit?.[0]
      const sizeUnit = size3AndUnitSplit?.[1]

      let sizeIntegerValue = size1 * size2 * size3;

      if (sizeUnit === PRODUCT_LENGTH_UNITS[1]) {
        //Centimeters already
      } else if(sizeUnit === PRODUCT_LENGTH_UNITS[2]) {
        //Foot to CM
        sizeIntegerValue *= 30.48
      } else if(sizeUnit === PRODUCT_LENGTH_UNITS[0]) {
        //Inches to CM
        sizeIntegerValue *= 2.54
      }
      currSizeObj.value = sizeIntegerValue;
    } else if (hasSubstring(currSizeObj.size, PRODUCT_LENGTH_UNITS)) {
      lengthOrWidthCount++;
      const sizeSplit = currSizeObj.size.split(' ')
      const size1 = sizeSplit?.[0]
      const sizeUnit = sizeSplit?.[1]

      let sizeIntegerValue = size1;

      if (sizeUnit === PRODUCT_LENGTH_UNITS[1]) {
        //Centimeters already
      } else if(sizeUnit === PRODUCT_LENGTH_UNITS[2]) {
        //Foot to CM
        sizeIntegerValue *= 30.48
      } else if(sizeUnit === PRODUCT_LENGTH_UNITS[0]) {
        //Inches to CM
        sizeIntegerValue *= 2.54
      }
      currSizeObj.value = sizeIntegerValue;
    } else if (hasSubstring(currSizeObj.size, PRODUCT_WEIGHT_UNITS)) {
      weightCount++;
      let size1 = 0;
      let size2 = 0;
      if (currSizeObj.size.includes('lb') && currSizeObj.size.includes('oz')) {
        const sizeSplit = currSizeObj.size.split(' lb ')
        size1 = sizeSplit?.[0]
        size2 = sizeSplit?.[1].split(' oz')?.[0]
      } else if (currSizeObj.size.includes('lb')) {
        const sizeSplit = currSizeObj.size.split(' lb')
        size1 = sizeSplit?.[0]
        size2 = '0'
      } else if (currSizeObj.size.includes('oz')) {
        const sizeSplit = currSizeObj.size.split(' oz')
        size1 = '0'
        size2 = sizeSplit?.[0]
      }

      currSizeObj.value = (size1 * 16) + size2;

    } else if (currSizeObj.size.startsWith('Set of items: ')) {
      setCount++;
      currSizeObj.value = currSizeObj.size.split('Set of items: ')?.[1]
    } else if (currSizeObj.size.startsWith('Custom: ')) {
      //Do nothing
    } else {
      //Do nothing
    }
  }

  sizeObjects?.sort((a, b) => {
    return a.value - b.value;
  })

  let sortedSizes = sizeObjects.map(sizeObj => {
    return sizeObj.size;
  });

  return sortedSizes;

}