import ROUTES, { RenderRoutes } from "./routes";
import { BrowserRouter as Router } from "react-router-dom";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";
import { Hub, Auth } from "aws-amplify";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loginUser, deleteUser } from "./app/features/auth/userDataSlice";
import useTidioScript from "./hooks/useTidioScript"
import useCallRailScript from "./hooks/useCallRailScript"
import useGoogleTagManager from "./hooks/useGoogleTagManager";
import useGoogleTagManagerAds from "./hooks/useGoogleTagManagerAds";
import useFacebookPixel from "./hooks/useFacebookPixel";
import usePinterestPixel from "./hooks/usePinterestPixel";

const theme = createTheme();

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          //console.log("user signed in");
          const attributes = {
            ...data.attributes,
            username: "username" in data ? data.username : "",
          };
          //console.log("signin", attributes);
          dispatch(loginUser(attributes));
          break;
        case "cognitoHostedUI":
          console.log("federated sign in");
          getUser().then((userData) => {
            const attributes = {
              ...userData.attributes,
              username: "username" in userData ? userData.username : "",
            };
            //console.log("cognito", attributes);
            dispatch(loginUser(attributes));
          });
          break;
        case "signUp":
          //console.log("user signed up");
          break;
        case "signOut":
          //console.log("user signed out");
          dispatch(deleteUser());
          break;
        case "oAuthSignOut":
          //console.log("federated sign out");
          dispatch(deleteUser());
          break;
        case "signIn_failure":
          //console.log("user sign in failed");
          break;
        case "tokenRefresh":
          //console.log("token refresh succeeded");
          break;
        case "tokenRefresh_failure":
          //console.log("token refresh failed");
          break;
        case "configured":
          //console.log("the Auth module is configured");
          break;
        default:
          //console.log(event);
          //console.log("something happen in the default case ooop");
          break;
      }
    });
  }, [dispatch]);

  useGoogleTagManager();
  useGoogleTagManagerAds();
  useFacebookPixel();
  usePinterestPixel();
  useTidioScript();
  useCallRailScript();

  const getUser = async () => {
    try {
      const userData = await Auth.currentAuthenticatedUser();
      return userData;
    } catch {
      return console.log("Not Signed in.");
    }
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Router>
          <div>
            <RenderRoutes routes={ROUTES} />
          </div>
        </Router>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
