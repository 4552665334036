import {
  Typography,
  Divider,
  Container,
  styled,
  FormControl,
  outlinedInputClasses,
  selectClasses,
  svgIconClasses,
  Select,
  MenuItem,
  menuItemClasses,
  useMediaQuery,
  useTheme,
  ButtonBase,
  Popover,
  Box,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import ListIcon from "@mui/icons-material/List";
import makeStyles from "@mui/styles/makeStyles";
import { useState, useEffect } from "react";
import GalleryView from "../../components/GalleryView";
import SocialMedia from "../../components/SocialMedia";
import { useParams } from "react-router-dom";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { PurposeCategoryMasterLayout } from "../../layouts";
import SupportYourView from "../../components/SupportYourView";
import {
  handlePurposeFilter,
  handleSortFilter,
  handleOptionsFilter
} from "../../util/filterProducts";
import useGalleryPages from "../../hooks/useGalleryPages";
import { getPurposeCategoryById } from "../../util/purposeCategories";
import {
  getProductCategoryById,
} from "../../util/productCategories";
import BrandSupportsHeader from "../../components/BrandSupportsHeader";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { shuffle } from "../../util/utilities";

const useStyles = makeStyles({
  divMarginBottom: { height: "var(--dividerHeight)" },
  titleText: {
    fontSize: "5rem",
    color: "var(--mintGreen)",
  },
  subtitleText: {
    fontSize: "1.563rem",
    color: "var(--mintGreen)",
    marginBottom: 50,
  },
  categoryMenu: {
    marginTop: 20,
    marginLeft: 10,
  },
  categoryViewGalleryGridItem: {
    margin: "0 auto",
  },
  browseByText: {
    fontSize: "1.25rem",
  },
  categoryViewDiv: {
    marginBottom: 70,
    margin: "auto",
  },
  galleryViewDiv: {
    marginBottom: 50,
  },
  galleryViewMobileDiv: {
    marginBottom: 50,
    marginRight: 0,
  },
  carouselHeader: {
    marginTop: 50,
    marginBottom: 30,
  },
  socialMedia: {
    marginTop: 50,
    marginBottom: 90,
  },
});

const DropdownButtonContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  marginRight: 50,
});

const StyledFormControl = styled(FormControl)({
  [`& .${outlinedInputClasses.root}`]: {
    marginRight: 10,
    height: 40,
    borderRadius: 25,
    border: 'none',
    backgroundColor: "#F7F7F7",
    [`& .${outlinedInputClasses.notchedOutline}`]: {
      border: 'none',
    },
    [`&:${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
      border: 'none',
    },
    [`&:${outlinedInputClasses.hover} .${outlinedInputClasses.notchedOutline}`]: {
      border: 'none',
    },
    [`& .${selectClasses.select}`]: {
      paddingRight: 0,
      paddingLeft: 27,
    },
  },
  [`& .${svgIconClasses.root}`]: {
    // arrow down icon
    paddingRight: 0,
  },
});

const StyledMenuItem = styled(MenuItem)({
  backgroundColor: "#FFFFFF",
  [`&.${menuItemClasses.selected}`]: {
    backgroundColor: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
  },
});

const DropdownButton = (props) => {
  // Structure of options is [{label: "", value: ""}, {label: "", value: ""}]
  const { label, options, selected, handleClick, isMobile } = props;

  return (
    <StyledFormControl>
      <Select
        value={selected}
        onChange={handleClick}
        renderValue={() => (
          <Typography align="center" variant="h6" className={"roboto-thin"} sx={{fontSize: isMobile && "1rem"}}>
            {label}
          </Typography>
        )}
        displayEmpty
        IconComponent={ExpandMore}
        sx={{
          ".MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input":
          {
            pl: 2,
            pr: isMobile ? 4 : 4.5,
          },
          [`& .${outlinedInputClasses.root}`]: {
            height: isMobile ? 30 : 40,
          }
        }}
      >
        {options &&
          options.map((option, index) => {
            return (
              <StyledMenuItem key={index} value={option.value}>
                <Typography className={"roboto-regular"}>
                  {option.label}
                </Typography>
              </StyledMenuItem>
            );
          })}
      </Select>
    </StyledFormControl>
  );
};

function splitArray(array, chunkSize) {
  const numOfChunks = Math.ceil(array.length / chunkSize);
  const result = [];

  for (let i = 0; i < numOfChunks; i++) {
    result.push(array.slice(i * chunkSize, (i + 1) * chunkSize));
  }

  return result;
}

function MultipleCategoriesLayout() {
  const DEFAULT_ID = 2;
  const classes = useStyles();
  const { categoryIds, title } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [productCategoryData, setProductCategoryData] = useState({});
  const [menu, setMenu] = useState([]);
  const [categoryView, setCategoryView] = useState([]);
  const [originalProductList, setOriginalProductList] = useState([]);
  const [currentProductList, setCurrentProductList] = useState([]);
  const [currentProductGallery, setProductGallery] = useState([[]]);
  const [purposeSelected, setSelectedPurpose] = useState("");
  const [optionSelected, setSelectedOption] = useState("");
  const [sortBySelected, setSortBySelected] = useState("");
  const [purposes, setPurposes] = useState([]);
  const [supportCarousel1Products, setSupportCarousel1Products] =
    useState([]);
  const [supportCarousel2Products, setSupportCarousel2Products] =
    useState([]);
  const [similarProductsData, setSimilarProducts] = useState([]);
  const [recentlyViewedProducts, setRecentlyViewed] = useState([]);
  // Temporary using this hook as a component toggle for categories
  const [signUpOption, setSignUpOption] = useState(false);
  const [filterOptions, setFilterOptions] = useState([]);
  const [loading, setLoading] = useState(true);

  const [sustainabilityProducts, setSustainabilityProducts] = useState([[]]);
  const [endPovertyProducts, setEndPovertyProducts] = useState([[]]);
  const [healthProducts, setHealthProducts] = useState([[]]);
  const [loadingSustainabilityProducts, setLoadingSustainabilityProducts] = useState(true);
  const [loadingEndPovertyProducts, setLoadingEndPovertyProducts] = useState(true);
  const [loadingHealthProducts, setLoadingHealthProducts] = useState(true);
  const [pageTitle, setPageTitle] = useState("");





  useEffect(() => {

    // Create purpose list on first render
    // getCategoryById(id, (data) => {
    //   setProductCategoryData(data);
    // });

    //Sustainability Products
    getPurposeCategoryById('60', (data) => {
      setSustainabilityProducts(splitArray(data, 12));
      setLoadingSustainabilityProducts(false);
    });

    //End Poverty Products
    getPurposeCategoryById('70', (data) => {
      setEndPovertyProducts(splitArray(data, 12));
      setLoadingEndPovertyProducts(false);
    });

    //Health Products
    getPurposeCategoryById('80', (data) => {
      setHealthProducts(splitArray(data, 12));
      setLoadingHealthProducts(false);
    });

  }, []);

  useEffect(() => {

    //decode title and set pageTitle
    setPageTitle(decodeURI(title));

    const requestIds = categoryIds.split("+");

    // Fetches product category data for multiple request IDs and add them to a state variable
    const promises = requestIds.map((requestId) => {
      return new Promise((resolve, reject) => {
        getProductCategoryById(requestId, (data) => {
          resolve(data);
        });
      });
    });

    Promise.all(promises).then((data) => {
      const allProducts = data.flat();
      //dedupe products on productID
      const uniqueProducts = allProducts.filter((thing, index, self) =>
        index === self.findIndex((t) => (
          t.productID === thing.productID
        ))
      )
      const products = shuffle(uniqueProducts);

      setProductGallery([products]);
      const filtOptionsSet = new Set();
      const filtPurposesArray = [];
      products.forEach(o => {
        o.options.forEach(e => {
          filtOptionsSet.add(e.size);
          filtOptionsSet.add(e.color);
        })
        o.purposes.forEach(purpose => {
          if (purpose.higherPurposeID == -1) {
            if (!filtPurposesArray.some(p => p.label === purpose.purposeName)) {
              filtPurposesArray.push({ label: purpose.purposeName, value: purpose.productPurposeID });
            }
          }
        })
      });
      setPurposes(filtPurposesArray);

      setFilterOptions(Array.from(filtOptionsSet).map(f => {
        return { label: f, value: f }
      }));
      setOriginalProductList(products);
      setCurrentProductList(products);
      setLoading(false);
    });

  }, [categoryIds]);

  // Update gallery pages whenever a filter causes the product list to update
  useGalleryPages(currentProductList, setProductGallery, 32);

  //Changes Title based on Category chosen
  useDocumentTitle(
    productCategoryData.hasOwnProperty("name")
      ? productCategoryData.name + " Category"
      : "Purposer"
  );

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <PurposeCategoryMasterLayout>
      <>
      <br />
      {!isMobile && <br />}
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Box display="flex" alignItems="center">
            <Typography
              // align="center"
              // display="block"
              component="span"
              //className={classes.titleText}
              variant="h1"
              className={"roboto-medium"}
              sx={{
                color: "var(--mintGreenSolid)",
                fontSize: isMobile ? "1.5rem" : "2.5rem",
                // marginTop: 4
              }}
            >
              {pageTitle ? pageTitle : ""}
            </Typography>
          </Box>
        </Box>
        {isMobile && <br />}
        {!isMobile ? (
          <div className={classes.galleryViewDiv}>
            <Container maxWidth="xl">
              <DropdownButtonContainer>
                {/* <DropdownButton
                    label={"Filter"}
                    options={filterOptions}
                    selected={optionSelected}
                    //display="none"
                    handleClick={(event) => {
                      const newOption = event.target.value;
                      setSelectedOption(newOption);
                      handleOptionsFilter(
                        newOption,
                        originalProductList,
                        setCurrentProductList
                      );
                    }}
                  /> */}
                <DropdownButton
                  label={"Purposes"}
                  isMobile={isMobile}
                  options={purposes}
                  selected={purposeSelected}
                  handleClick={(event) => {
                    const newPurpose = event.target.value;
                    setSelectedPurpose(newPurpose);
                    handlePurposeFilter(
                      newPurpose,
                      originalProductList,
                      setCurrentProductList
                    );
                  }}
                />
                <div style={{ width: 35 }} />
                <DropdownButton
                  label={"Sort By"}
                  isMobile={isMobile}
                  options={[
                    // { label: "Most Popular", value: "popular" },
                    { label: "Price High to Low", value: "highToLow" },
                    { label: "Price Low to High", value: "lowToHigh" },
                    // { label: "Sale", value: "sale" },
                  ]}
                  selected={sortBySelected}
                  handleClick={(event) => {
                    const newValue = event.target.value;
                    setSortBySelected(newValue);
                    handleSortFilter(
                      newValue,
                      currentProductList,
                      setCurrentProductList
                    );
                  }}
                />
              </DropdownButtonContainer>
            </Container>
            <GalleryView 
              productPages={currentProductGallery} 
              showLoadingIcon={loading} 
              itemsPerRow={6}
              extraPadding={true}
            />
          </div>
        ) : (
          <>
            {/* <div className={classes.categoryViewDiv}>
              <CategoryView data={categoryView} parentCategoryId={id} />
            </div> */}
            <div className={isMobile ? classes.galleryViewMobileDiv : classes.galleryViewDiv}>
              <DropdownButtonContainer
                sx={{
                  marginRight: "0px",
                }}
              >
              <ButtonBase
                  onClick={handleClickPopover}
                  sx={{
                    marginRight: "auto",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: 25,
                    backgroundColor: "#F7F7F7",
                    marginLeft: 2,
                  }}
                >
                  <Typography
                    align="center"
                    className={"roboto-thin"}
                    sx={{ fontSize: 16, textJustify: 'center'}}
                    variant="h6"
                    ml={2}
                  >
                    {"Browse"}
                  </Typography>
                  <KeyboardArrowDownIcon 
                    sx={{ 
                      fontSize: 30, 
                      ml: 1, 
                      color: "#A9A9A9"
                    }} 
                  />
                </ButtonBase>
                <Popover
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  {/* <Box sx={{ m: 1, p: 1 }}>
                    <CategoryMenu
                      categoryData={[productCategoryData]}
                      expandedCategory={subcategoryId ? subcategoryId : id}
                    />
                  </Box> */}
                </Popover>
                <DropdownButton
                  label={"Purposes"}
                  isMobile={isMobile}
                  options={purposes}
                  selected={purposeSelected}
                  handleClick={(event) => {
                    const newPurpose = event.target.value;
                    setSelectedPurpose(newPurpose);
                    handlePurposeFilter(
                      newPurpose,
                      originalProductList,
                      setCurrentProductList
                    );
                  }}
                />
                <div style={{ width: 25 }} />
                <DropdownButton
                  label={"Sort"}
                  isMobile={isMobile}
                  options={[
                    // { label: "Most Popular", value: "popular" },
                    { label: "Price High to Low", value: "highToLow" },
                    { label: "Price Low to High", value: "lowToHigh" },
                    // { label: "Sale", value: "sale" },
                  ]}
                  selected={sortBySelected}
                  handleClick={(event) => {
                    const newValue = event.target.value;
                    setSortBySelected(newValue);
                    handleSortFilter(
                      newValue,
                      currentProductList,
                      setCurrentProductList
                    );
                  }}
                />
              </DropdownButtonContainer>
              <GalleryView 
                mobile 
                productPages={currentProductGallery} 
                showLoadingIcon={loading} 
                itemsPerRow={6}
                extraPadding={true}
              />
            </div>
          </>
        )}
      </>
      {/* <div style={{backgroundColor: "var(--pastelBeige)", padding: "24px"}}>
        <ShopByCategoryView title={"Shop for your favorite people, places, and pets"}/>
      </div> */}
      {/* {!signUpOption && <SignUp />} */}
      {/*<TrendiestView data={trendiestView} />*/}
      <div className={classes.divMarginBottom} />
      <BrandSupportsHeader
        title={'Our Common Home, Planet, Trees, and Oceans'}
        title_url={'/shop/purpose/60'}
      />
      <GalleryView
        productPages={sustainabilityProducts}
        itemsPerRow={6}
        extraPadding={true}
        showLoadingIcon={loadingSustainabilityProducts}
      />
      <BrandSupportsHeader
        title={'Mental and Physical Health Initiatives'}
        title_url={'/shop/purpose/80'}
      />
      <GalleryView
        productPages={healthProducts}
        itemsPerRow={6}
        extraPadding={true}
        showLoadingIcon={loadingHealthProducts}
      />
      <BrandSupportsHeader
        title={'Poverty Solutions, Ethical Production and The Dignity of Every Person'}
        title_url={'/shop/purpose/70'}
      />
      <GalleryView
        productPages={endPovertyProducts}
        itemsPerRow={6}
        extraPadding={true}
        showLoadingIcon={loadingEndPovertyProducts}
      />
      <div className={classes.divMarginBottom} />
      <SupportYourView />

      {/* <Container maxWidth="xl">
        <Typography
          align="center"
          display="block"
          variant="h4"
          className={clsx(classes.carouselHeader, "roboto-bold")}
        >
          Similar Products
        </Typography>
      </Container>
      <CarouselProductView
        data={similarProductsData.length > 0 ? similarProductsData : null}
        hideSeller
        hidePurpose
      />
      <Container maxWidth="xl">
        <Typography
          align="center"
          display="block"
          variant="h4"
          className={clsx(classes.carouselHeader, "roboto-bold")}
        >
          Best Sellers
        </Typography>
      </Container>
      <CarouselProductView
        data={recentlyViewedProducts.length > 0 ? recentlyViewedProducts : null}
        hideSeller
        hidePurpose
      /> */}
      {/*<div className={classes.socialMedia}>
        <SocialMedia />
      </div>
      <OtherCategories otherCategories={otherCategories} />
      <div
        style={{
          height: "var(--dividerHeight)",
          backgroundColor: "var(--coral)",
        }}
      />*/}
      {/*<Blog />*/}
      {/* <LoveInstagram /> */}
      <SocialMedia />
      <div style={{ height: "var(--dividerHeight)" }} />
      <div style={{ height: "var(--dividerHeight)" }} />
    </PurposeCategoryMasterLayout>
  );
}

export default MultipleCategoriesLayout;
