import {
  Box,
  Container,
  Divider,
  TextField,
  Grid,
  ButtonBase,
  Typography,
  InputLabel,
  FormControl,
  Select,
} from "@mui/material";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { PurposeCategoryMasterLayout } from "../../layouts";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import useDocumentDescription from "../../hooks/useDocumentDescription";

const articles1 = [
  {
    title: "B’s Gourmet Truffled Nuts",
    text: "These truffles cashews are the perfect healthy snack for dad. You can pair them with a beer or glass of wine. They also make the perfect addition to any cheese board. A portion of the net proceeds are donated to an organization called the Cancer Wellness Center.",
    link: "/shop/product/4120D95A-B174-4987-9DFE-AF4ACCE11A2C/Gourmet+Black+Truffle+Nuts+Handcrafted+Cashews+Mothers+Day+Nuts+Gift+Basket+Healthiest+Nuts+6oz+Pack+of+3",
    img_url:
      "https://d2eudfgh8kpk8j.cloudfront.net/common/All+Natural+Gourmet+Nuts++Handcrafted+Roasted+Cashews.png",
  },
  {
    title: "Handmade Beer Coaster",
    text: "Is your dad into art and traveling? These drink coasters are handcrafted from vintage artwork and upcycled materials. They are repurposed and upcycled into sustainable coasters. Get dad this 4 pack of sustainable coasters!",
    link: "/shop/product/D7C29896-10BD-4E89-BC78-2CD2B7CB72AF/Delaware+Map+Drink+Coasters+Pack+of+4+4x4+Inches+Handmade+from+Repurposed+Maps+Tabletop+Protection+Waterproof+and+Non+Toxic",
    img_url:
      "https://d2eudfgh8kpk8j.cloudfront.net/common/Map+Drink+Coasters++Pack+of+4++4x4+Inches++Handmade+from+Repurposed+Maps++Tabletop+Protection.png",
  },
  {
    title: "Delicious Colombian Cubed Instant Coffee",
    text: "This coffee is delicious and sustainable. It is made from 100% organic coffee beans directly from Colombian farmers. It’s also sweetened with organic panela sugar. The perfect gift for dads that are coffee lovers.",
    link: "/shop/product/5824C88F-9279-4136-BC0C-548089163AC4/Delicious+Colombian+Sourced+Cubed+Instant+Coffee+with+Natural+Organic+Sweetener+to+Promote+Healthy+Start+to+Every+Day+48+Pack+of+Coffee+Cubes",
    img_url:
      "https://d2eudfgh8kpk8j.cloudfront.net/common/Delicious+Colombian-Sourced+Cubed+Instant+Coffee+with+Natural%2C+Organic+Sweetener+.jpg",
  },
  {
    title: "Ocean Design Sterling Silver Ring",
    text: "The perfect gift for dads that surf and love the ocean! This sterling silver ring has a beautiful handmade design of a wave. A portion of the proceeds are given to the Surfrider Foundation, Save the Waves and Windansea Surf Club.",
    link: "/shop/product/01AD0A4A-C544-4287-B6A8-2336057E0459/Sterling+Silver+Ring+Thick+Stackable+Ring+Ocean+Design+Ring+Ring+For+Him+Gift+For+Her",
    img_url:
      "https://d2eudfgh8kpk8j.cloudfront.net/common/Sterling+Silver+Ring++Thick+Stackable+Ring++Ocean+Design+Ring++Ring+For+Him+.png",
  },
  {
    title: "Round Aviator Sunglasses, sustainably made",
    text: "With summer around the corner, sunglasses are a must for the hot, sunny days ahead of us. Get dad these round aviator sunglasses for men. Not only are they made with sustainable products, but they also have 100% UV protection.",
    link: "/shop/product/F125C082-BD53-42BB-B7EF-2FF07BAC5A8E/Round+Aviator+Sunglasses+Brown+Lenses+Unisex+Gold+Metal+Frame+Sustainable+Stainless+Steel+100+UV+Protection+400",
    img_url:
      "https://d2eudfgh8kpk8j.cloudfront.net/common/Round+Aviator+Sunglasses%2C+Brown+Lenses++Unisex+Gold+Metal+Frame.jpg",
  },
  {
    title: "Bamboo Utensils Set For On The Go Reusable Utensils & Straws",
    text: "Is your father looking to live a more eco-friendly lifestyle? This the perfect gift for him! This set is made out of 100% bamboo and includes a fork, knife, spoon, chopsticks and straws. It comes in a navy blue wrap, making it easy to take it everywhere.",
    link: "/shop/product/3D0A13F5-BBC1-4293-BC0E-BE45D3A0E500/Bamboo+Travel+Utensils+Set+Bamboo+Fork+Knife+Spoon+Chopsticks+Straws+100+Organic+Cotton+Wrap+On+The+Go+Reusable+Utensils+1+Set",
    img_url:
      "https://d2eudfgh8kpk8j.cloudfront.net/common/Bamboo+Travel+Utensils+Set++Bamboo+Fork%2C+Knife%2C+Spoon%2C+Chopsticks%2C+Straws.jpg",
  },
  {
    title: "EMT stuffed plush bear",
    text: "This God Bless EMT stuffed teddy bear is a cute gift idea for fathers who are EMTs. It is navy blue and about 9” tall. It also includes a high quality hang tag featuring a bible verse.",
    link: "/shop/product/CF6FD43D-A6EC-4824-8F7D-A40AB835FBCC/God+Bless+EMT+Stuffed+Teddy+Bear+9+Dark+Blue+Corporate+Teddy+Bears+Embroidered+Plush+Toys",
    img_url:
      "https://d2eudfgh8kpk8j.cloudfront.net/common/God+Bless+EMT+Stuffed+Teddy+Bear%2C+9+Dark+Blue+Corporate+Teddy+Bears.jpg",
  },
  {
    title: "US Army stuffed plush bear",
    text: "A patriotic teddy bear is a great gift for fathers who are veterans or currently serve in the US Army. This plush teddy bear is dark green, embroidered with US Army insignia on the chest, and has a red, white and blue ribbon.",
    link: "/shop/product/32E3ACC5-98EA-4F5E-BCFC-451545942F6C/Green+US+Army+Stuffed+Teddy+Bear+Embroidered+with+Army+Insignia+on+Chest+9+Patriotic+Teddy+Bear",
    img_url:
      "https://d2eudfgh8kpk8j.cloudfront.net/common/Green+US+Army+Stuffed+Teddy+Bear%2C+Embroidered+with+Army+Insignia+on+Chest.jpg",
  },
  {
    title: "Firefight stuffed teddy bear ",
    text: "A God Bless Firefighter Teddy Bear is a nice sentiment for fathers who are or have been firefighters. This patriotic plushie is red, white and has an embroidered fire helmet on the chest. ",
    link: "/shop/product/8EEC3B46-765B-4DF9-8C4A-27F1FAF7919A/Red+and+White+God+Bless+Firefighter+Teddy+Bear+Embroidered+9+Patriotic+Plushie",
    img_url:
      "https://d2eudfgh8kpk8j.cloudfront.net/common/Red+and+White+God+Bless+Firefighter+Teddy+Bear%2C+Embroidered.jpg",
  },
  {
    title: "Wave Design Sterling Silver necklace for surfers ",
    text: "The perfect gift for dads who love the beach and surfing. This men’s necklace is handmade out of sterling silver and includes a silver chain with patina. A portion of the proceeds are given to the Surfrider Foundation, Save the Waves and Windansea Surf Club.",
    link: "/shop/product/5E8128EF-DCBC-45B6-9837-AB36F9D51ACB/Sterling+Silver+Circle+Wave+Necklace+Sterling+Silver+Chain+With+Patina+Necklace",
    img_url:
      "https://d2eudfgh8kpk8j.cloudfront.net/common/Sterling+Silver+Circle+Wave+Necklace++Sterling+Silver+Chain+With+Patina+Necklace.jpg",
  },
];

const useStyles = makeStyles({
  headerContainer: {
    position: "relative",
  },
  headerImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "top",
  },
  textContainer: {
    padding: "40px 0px",
  },
  containerMargins: {
    margin: "auto 15px",
  },
  headerText: {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  articleImage: {
    maxWidth: "100%",
    height: 400,
    objectFit: "cover",
    objectPosition: "top",
    borderRadius: 16
  },
  logoImage: {
    maxHeight: "100%",
    width: 90,
  },
  articleText: {
    // padding: "20px",
    textAlign: "center",
    marginBottom: 4
  },
  readMoreButton: {
    backgroundColor: "var(--beige)",
    padding: "10px 35px",
    borderRadius: 8,
    marginTop: 16
  },
  viewMoreButton: {
    marginBottom: 50,
    padding: "10px 35px",
    border: "1px solid black",
  },
  gridContainer: {
    marginBottom: 50,
    marginTop: 50,
  },
  formControl: {
    minWidth: 120,
    float: "right",
    marginBottom: 10,
  },
});


const ProductArticle = (props) => {
  const { products } = props;
  const classes = useStyles();
  const [state, setState] = React.useState({
    filter: "",
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  return (
    <Container maxWidth="lg" align="center">
      {products.map((article, index) => {
        return (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            align="center"
            className={classes.gridContainer}
          >
            <Grid item md={6} sm={12}>
              <img
                src={article.img_url}
                alt="article"
                className={classes.articleImage}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <Typography className={classes.articleText} variant="h3"
                sx={{ fontSize: 30 }}
              >
                <b>
                  {article.title}
                </b>
              </Typography>
              <Typography className={classes.articleText}>
                {article.text}
              </Typography>
              <ButtonBase
                className={classes.readMoreButton}
                href={article.link}
                target="_blank"
              >
                <Typography>
                  Shop Now
                </Typography>
              </ButtonBase>
            </Grid>
          </Grid>
        );
      })}
    </Container>
  );
};

const BlogLayoutFathersDay = () => {
  const classes = useStyles();

  let title = "Celebrate Father’s Day With The Best Gifts For All Father Figures | Father’s Day Gifts | Gifts for Dad | Cool Father’s Day Gifts Dad Will Actually Love | Unique Father’s Day Gifts";
  useDocumentTitle(
    title.replace(/^(.{60}[^\s]*).*/, "$1")
  );
  useDocumentDescription(
    title.replace(/\W+/g, " ").replace(/^(.{160}[^\s]*).*/, "$1") + " - Shop on Purposer.com"
  );

  return (
    <PurposeCategoryMasterLayout>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mx: 10,
        }}
      >
        <Box sx={{ my: 5 }}>
          <Typography
            align="center"
            variant="h1"
            sx={{ fontSize: "3rem", mt: 4 }}
          >
            <b>{`Celebrate Father’s Day With The Best Gifts For All Father Figures | Father’s Day Gifts | Gifts for Dad | Cool Father’s Day Gifts Dad Will Actually Love | Unique Father’s Day Gifts`}</b>
          </Typography>
        </Box>
      </Box>
      <Container>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box sx={{ mb: 8 }}>
            <Typography
              align="center"
              variant="h1"
              sx={{ fontSize: "2rem" }}
            >
              {`Our fathers are such special men because they dedicate their lives for us and sacrifice so much for those they love. This year, let’s express our gratitude to them with prayers, quality time and special gifts!`}
            </Typography>
          </Box>
        </Box>
      </Container>
      <Box sx={{ height: 680 }}>
        <img
          src={
            "https://d2eudfgh8kpk8j.cloudfront.net/common/fathers-day-gifts.jpg"
          }
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "center",
          }}
          alt="The best father's day gifts for the father's you love. Let's shower our dads with love, respect, dignity and beautiful gifts!"
        />
      </Box>
      <Container maxWidth="xl">
        <Container
          maxWidth="xl"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            // my: 5,
            mt: 8
          }}
        >
          <Divider
            sx={{
              height: 4,
              backgroundColor: "black",
              width: "30%",
            }}
          />
          <Typography
            variant="h2"
            align="center"
            className={classes.containerMargins}
            sx={{fontSize: "2rem"}}
          >
            <b>10 Gift Ideas for Dad</b>
          </Typography>
          <Divider
            sx={{
              height: 4,
              backgroundColor: "black",
              width: "30%",
            }}
          />
        </Container>
      </Container>
      <Container maxWidth="lg" justifyContent="center"
        sx={{
          // display: "flex",
          // flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          // overflow: "hidden",
          // mx: 5,
          mt: 2
        }}>

        <Typography variant="h2" className={classes.articleText} sx={{fontSize: "20px"}}>
          {`Father's Day 2023 is right around the corner, which means you have a couple of weeks left to find a special gift for the father figures in your life. Show your father how much you appreciate them by choosing the perfect father’s day gifts that will sure make them smile and feel special. Getting the perfect gift can sometimes be a challenge, due to the fact that fathers have diverse interests and preferences. I think most of us can agree that shopping for mom is way easier than shopping for dad, right? No need to stress because the Purposer team has you covered. We have created a list of 10 unique, thoughtful and ethical gift ideas for your dad, stepdad, grandfather, uncle or other influential father figures. This list is compiled of a wide array of gift options that cater to all interests and likes. All our father’s day gifts are high quality and range in price points. More importantly, they are products from ethical brands that do good in the world, which means guilt-free shopping!! Feel good about making a purchase that will not only make dad happy but also makes a positive impact in the world.`}
        </Typography>
      </Container>


      <ProductArticle products={articles1} />
      <Container maxWidth="lg" justifyContent="center"
        sx={{
          // display: "flex",
          // flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          // overflow: "hidden",
          // mx: 5,
          mb: 8
        }}>

        <Typography className={classes.articleText}>
          {`Fathers day is the perfect occasion to appreciate the incredible father figures in our lives. It's a day to express gratitude for a father’s love, guidance, and unwavering support.  We know shopping for Dad can be tricky which is why we highly suggest consulting this list we made. Whatever the father’s day gift may be, your gesture will show your love and appreciation. The most important aspect of any gift is the sentiment behind it. People want to feel that the gift was chosen especially for them. It shows that you care and know the person. So, take the time to select gifts that reflect your dad's personality and interests, and make this Father's Day truly memorable. Lastly, remember there are other ways of making fathers day meaningful. Spend quality time with your father figures and create long lasting memories. Whether it be going for a hike, playing golf, watching a movie, praying together or cooking a meal together. Those memories last a lifetime and will help celebrate the pillars of strengths and love in our life. Happy fathers day to all the special father figures out there. We’re grateful for you and hope you feel as special as you make all us feel everyday. -The Purposer Team`}
        </Typography>
      </Container>
    </PurposeCategoryMasterLayout>
  );
};

export default BlogLayoutFathersDay;
