import { Typography, Container, TextField, Button, Box, Grid, ButtonBase, Modal, Divider } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import { useState, useEffect } from "react";
import { AdminPortalManagementMaster } from "..";
import homepageCarouselHeaderData from "../../json/homepage_carousel_mothersday.json";
import makeStyles from "@mui/styles/makeStyles";
import { styled } from "@mui/material/styles";
import {
  NavigateBefore as ArrowBack,
  NavigateNext as ArrowForward,
} from "@mui/icons-material";
import Carousel from "react-multi-carousel";
import { getPressReleases, createPressRelease, updatePressRelease, deletePressRelease } from "../../util/admin";
import FileUploadV2 from "../../components/FileUploadV2";
import { uploadImageArray } from "../../util/images";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";


function PressReleaseUpdaterLayout() {
  const [formValues, setFormValues] = useState({
    title: '',
    description: '',
    link: '',
    image: [],
    logo: [],
    pressID: '',
    press_date: ''
  })

  const handleFormValues = (name, value) => {
    setFormValues((preValues) => {
      return { ...preValues, [name]: value };
    });
  };

  const handleDateChange = (newValue) => {
    setFormValues((preValues) => {
        return { ...preValues, "press_date": newValue };
    });
};

  const useStyles = makeStyles({
    galleryDiv: {
      paddingBottom: 10,
      overflowX: "hidden",
      display: "relative",
    },
    // galleryContainer: {
    //   display: "relative",
    // },
    galleryGrid: {
      padding: 40,
      margin: "auto",
      marginBottom: "32px"
    },
    galleryItem: {
      paddingTop: 15,
      paddingBottom: 15,
      maxWidth: "100%",
    },
    galleryButton: {
      width: "35px",
      height: "35px",
      borderRadius: "25px",
      border: "none",
      backgroundColor: "#EAF1EF",
      margin: 7,
    },
    logoImage: {
      maxHeight: "100%",
      width: 200,
    },
  });

  const classes = useStyles()

  const [pressReleases, setPressReleases] = useState([]);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [currentId, setCurrentId] = useState([]);

  useEffect(() => {
  getPressReleases((data) => {
    if (!data.hasOwnProperty("error") && !data.hasOwnProperty("message")) {
        setPressReleases(data.results);
    }
  })}, []);

  const maxNumPages = 5;

  const handleSubmit = async (e) => {
    if (!isLoading) {
      e.preventDefault();
      setIsLoading(true);
      //Updating case
      if (currentId && currentId.length > 0) {
        const imgUrlArray = await uploadImageArray(formValues.image);
        const logoUrlArray = await uploadImageArray(formValues.logo);
        updatePressRelease(currentId, imgUrlArray[0], logoUrlArray[0], formValues.title, formValues.description, formValues.link, formValues.press_date, (data) => {
          setIsLoading(false)
          window.location.reload(false);
        })
      }
      //Creation case
      else {
        const imgUrlArray = await uploadImageArray(formValues.image);
        const logoUrlArray = await uploadImageArray(formValues.logo);
        createPressRelease(imgUrlArray[0], logoUrlArray[0], formValues.title, formValues.description, formValues.link, formValues.press_date, (data) => {
          setIsLoading(false)
          window.location.reload(false);
        })
      }
    }
    setIsLoading(false);
  }

  const responsive = {
    break1: {
      breakpoint: { max: 4000, min: 0 },
      items: 1,
    },
  };

  const handleDelete = () => {
    if (!isLoading) {
      setIsLoading(true)
      deletePressRelease(currentId, (data) => {
        setIsLoading(false)
        setOpenConfirmDelete(false)
        window.location.reload(false);
      })
    }
  }

  const ConfirmDeleteModal = (props) => {
    const deleteBox = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };

    return (
      <Modal open={openConfirmDelete} handleClose={() => setOpenConfirmDelete(false)}>
        <Box sx={deleteBox}>
          <Typography variant="h5" style={{ color: "var(--coral)", fontWeight: "bold" }} textAlign="center">CAREFUL</Typography>
          <div style={{ height: "8px" }} />
          <Typography textAlign="center">You are about to delete this press release item. Are you sure you want to do this?</Typography>
          <div style={{ height: "16px" }} />
          <Grid container justifyContent="center" columnGap={2}>
            <Grid item>
              <Button variant="outlined" sx={{ borderColor: "black", color: "black" }} onClick={() => setOpenConfirmDelete(false)}>
                Go Back
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" sx={{
                bgcolor: "#D8000C", color: "white", '&:hover': {
                  backgroundColor: 'red',
                },
              }} onClick={() => handleDelete()}>
                Confirm and Delete
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    )
  }

  const CustomDots = ({ index, onClick }) => {
    const classes = useStyles();

    if (index < maxNumPages) {
      const NumberButton = (
        <ButtonBase
          onClick={(e) => {
            onClick();
            e.preventDefault();
          }}
          className={classes.galleryButton}
        >
          <Typography>{index + 1}</Typography>
        </ButtonBase>
      );

      if (index === maxNumPages - 1) {
        return (
          <>
            {NumberButton}
            <ButtonBase className={classes.galleryButton}>
              <Typography>...</Typography>
            </ButtonBase>
          </>
        );
      } else {
        return <>{NumberButton}</>;
      }
    } else {
      return <></>;
    }
  };

  const CustomButtonGroupAsArrows = ({ next, previous }) => {
    return (
      <>
        <ButtonBase
          sx={{
            position: "absolute",
            width: "35px",
            height: "35px",
            borderRadius: "25px",
            border: "none",
            left: { xs: "2%", sm: "18%", md: "23%", lg: "30%", xl: "30%" },
            bottom: 7,
            backgroundColor: "#EAF1EF",
            zIndex: 50,
          }}
          onClick={previous}
        >
          <ArrowBack sx={{}} />
        </ButtonBase>
        <ButtonBase
          sx={{
            position: "absolute",
            width: "35px",
            height: "35px",
            borderRadius: "25px",
            border: "none",
            right: { xs: "2%", sm: "18%", md: "23%", lg: "30%", xl: "30%" },
            bottom: 7,
            zIndex: 50,
            backgroundColor: "#EAF1EF",
          }}
          onClick={next}
        >
          <ArrowForward sx={{}} />
        </ButtonBase>
      </>
    );
  };

  const PostTextDiv = styled("div")({
    borderRadius: 15,
    backgroundColor: "var(--cream)",
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 3,
    flexGrow: 1,
    display: "flex",
    flexFlow: "column",
  });
  function ResultPages(pages, classes) {
    let productPages = [];
    for (let i = 0; i < pages.length; i++) {
      productPages.push(
        <Grid
          container
          direction="row"
          //justifyContent="center"
          className={classes.galleryGrid}
          key={i}
        >
          <>

            <Typography
              align="right"
              variant="h6"
              className={"roboto-regular-italic"}
            >
              Click the Image to modify this item on the homepage carousel.
            </Typography>
            <PostTextDiv>
              <img src={pages[i]?.image} className={classes.galleryItem}
                onClick={(e) => {
                  handleFormValues("title", pages[i]?.title);
                  handleFormValues("description", pages[i]?.description);
                  handleFormValues("link", pages[i]?.link);
                  handleFormValues("pressID", pages[i]?.pressID)
                  handleFormValues("image", [pages[i]?.image])
                  handleFormValues("logo", [pages[i]?.logo])
                  handleFormValues("press_date", [pages[i]?.press_date])
                  setCurrentId(pages[i]?.pressID)
                }}
              />
              <Typography
                align="right"
                variant="h6"
                className={"roboto-regular-italic"}
              >
                {pages[i]?.header}
              </Typography>
              <Typography
                align="left"
                variant="h6"
                className={"roboto-medium"}
              >
                {pages[i]?.description}
              </Typography>
              <Typography
                align="left"
                variant="h6"
                className={"roboto-medium"}
              >
                {pages[i]?.link}
              </Typography>
              <img src={pages[i]?.logo} className={classes.logoImage}/>

            </PostTextDiv>
          </>
        </Grid>)
    }
    return productPages;
  }

  return (
    <AdminPortalManagementMaster>
      <ConfirmDeleteModal openConfirmDelete={openConfirmDelete} setOpenConfirmDelete={setOpenConfirmDelete} />
      <Container style={{ padding: "36px" }}>
        <Typography variant="h5" style={{ color: "var(--coral)", fontWeight: "bold" }}>Press Releases</Typography>
        <Typography>Select a press release to populate these fields. To create a new item, simply fill out the blank fields and click "Create New Item."</Typography>
        <div style={{ height: "20px" }} />
        <Typography variant="h6">Title</Typography>
        <TextField
          value={formValues.title}
          onChange={(e) => {
            handleFormValues("title", e.target.value)
          }}
          size="small"
        />
        <div style={{ height: "12px" }} />
        <Typography variant="h6">Description</Typography>
        <TextField
          value={formValues.description}
          onChange={(e) => {
            handleFormValues("description", e.target.value)
          }}
          size="small"
        />
        <div style={{ height: "12px" }} />
        <Typography variant="h6">Link</Typography>
        <TextField
          value={formValues.link}
          onChange={(e) => {
            handleFormValues("link", e.target.value)
          }}
          size="small"
        />
        <Typography variant="h6">Date of Publication</Typography>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label="Date of Publication"
          value={formValues.press_date? formValues.press_date : null}
          onChange={handleDateChange}
          renderInput={(params) => <TextField {...params} />}
        />
        </LocalizationProvider>

        <Typography variant="h6">Image</Typography>
        <FileUploadV2
          formValueName={"image"}
          formValueArray={formValues.image}
          maxFiles={1}
          columns={4}
          handleFormValues={handleFormValues}
          buttonText={'upload: jpg, png'}
        />

        <Typography variant="h6">Logo</Typography>
        <FileUploadV2
          formValueName={"logo"}
          formValueArray={formValues.logo}
          maxFiles={1}
          columns={4}
          handleFormValues={handleFormValues}
          buttonText={'upload: jpg, png'}
        />
        <div style={{ height: "12px" }} />
        <Button
          onClick={handleSubmit}
          disabled={formValues.description.length < 1 || formValues.title.length < 1 || formValues.image.length < 1 || formValues.link.length < 1 || formValues.logo.length < 1 || formValues.press_date.length < 1}
          variant="contained"
          sx={{ backgroundColor: "var(--coral)", color: "white", '&:hover': { backgroundColor: "var(--mintGreenSolid)" } }}
        >
          <SaveIcon style={{ marginRight: "6px" }} />
          {currentId && currentId.length > 0? "Save":"Create New Item"}
        </Button>
        {formValues.pressID.length > 0 &&
          <Button variant="contained" sx={{
            backgroundColor: "#D8000C", color: "white", '&:hover': {
              backgroundColor: 'red',
            },
          }} onClick={setOpenConfirmDelete}>Delete Item</Button>}
          {(formValues.description.length > 0 || formValues.title.length > 0 || formValues.image.length > 0 || formValues.link.length > 0 || formValues.logo.length > 0 || formValues.press_date.length > 0) &&
            <Button variant="contained" sx={{
              backgroundColor: "#808080", color: "white", '&:hover': {
                backgroundColor: 'grey',
              },
            }} onClick={(e) => {
              handleFormValues("title", '');
              handleFormValues("description", '');
              handleFormValues("link", '');
              handleFormValues("pressID", '')
              handleFormValues("image", [])
              handleFormValues("logo", [])
              setCurrentId('')
            }}>Clear</Button>}
        <div style={{ height: "var(--dividerHeight)" }} />
        <Typography variant="h5" style={{ color: "var(--coral)", fontWeight: "bold" }}>Results</Typography>
        <Box sx={{ mb: 5, mx: 2 }}>
          <Carousel
            responsive={responsive}
            infinite={false}
            showDots={true}
            arrows={false}
            // renderButtonGroupOutside={true}
            customDot={<CustomDots />}
            customButtonGroup={<CustomButtonGroupAsArrows />}
            className={classes.galleryContainer}
          >
            {ResultPages(pressReleases, classes)}
          </Carousel>
          <div style={{ height: "var(--dividerHeight)" }} />
        </Box>
      </Container>
    </AdminPortalManagementMaster>

  )
}

export default PressReleaseUpdaterLayout;