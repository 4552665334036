import {
  Typography,
  Grid,
  Divider,
  Container,
  styled,
  FormControl,
  outlinedInputClasses,
  selectClasses,
  svgIconClasses,
  Select,
  MenuItem,
  menuItemClasses,
  useMediaQuery,
  useTheme,
  Box,
  ButtonBase,
  Popover,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import ListIcon from "@mui/icons-material/List";
import makeStyles from "@mui/styles/makeStyles";
import { useState, useEffect } from "react";
import CategoryView from "../../components/CategoryView";
import GalleryView from "../../components/GalleryView";
import CarouselProductView from "../../components/CarouselProductView";
import CategoryMenu from "../../components/CategoryMenu";
import { useParams } from "react-router-dom";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { PurposeCategoryMasterLayout } from "..";
import BrandSupports from "../../components/BrandSupports";
import SupportYourView from "../../components/SupportYourView";
import { getListOfProductsById } from "../../util/getProductsById";
import {
  handlePurposeFilter,
  handleSortFilter,
} from "../../util/filterProducts";
import useGalleryPages from "../../hooks/useGalleryPages";
import clsx from "clsx";
import LoveInstagram from "../../components/LoveInstagram";
import purposesData from "../../json/purposes.json";
import { getListOfPurposeCategoriesById, getPurposeCategoryById } from "../../util/purposeCategories";
import {
  getListOfProductCategoriesById,
  getProductCategoryById,
} from "../../util/productCategories";
import {
  getProductsBySearch,
} from "../../util/products";
import { useLocation } from "react-router-dom";
import { PRESET_SEARCHES } from "../../util/constants";
import BrandSupportsHeader from "../../components/BrandSupportsHeader";


const useStyles = makeStyles({
  divMarginBottom: { height: "var(--dividerHeight)" },
  titleText: {
    fontSize: "5rem",
    color: "var(--mintGreen)",
  },
  subtitleText: {
    fontSize: "1.563rem",
    color: "var(--mintGreen)",
    marginBottom: 50,
  },
  categoryMenu: {
    marginTop: 20,
    marginLeft: 10,
  },
  categoryViewGalleryGridItem: {
    margin: "0 auto",
  },
  browseByText: {
    fontSize: "1.25rem",
  },
  categoryViewDiv: {
    marginBottom: 70,
    margin: "auto",
  },
  galleryViewDiv: {
    marginBottom: 50,
  },
  carouselHeader: {
    marginTop: 50,
    marginBottom: 30,
  },
  socialMedia: {
    marginTop: 50,
    marginBottom: 90,
  },
});

const DropdownButtonContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
});

const StyledFormControl = styled(FormControl)({
  // maxWidth: "100%",
  [`& .${outlinedInputClasses.root}`]: {
    marginRight: 10,
    height: 40,
    borderRadius: 25,
    border: 'none',
    backgroundColor: "#F7F7F7",
    [`& .${outlinedInputClasses.notchedOutline}`]: {
      border: 'none',
    },
    [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
      border: 'none',
    },
    [`& .${selectClasses.select}`]: {
      paddingRight: 0,
      paddingLeft: 27,
    },
  },
  [`& .${svgIconClasses.root}`]: {
    paddingRight: -0,
  },
});

const StyledMenuItem = styled(MenuItem)({
  backgroundColor: "#FFFFFF",
  [`&.${menuItemClasses.selected}`]: {
    backgroundColor: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
  },
});

const DropdownButton = (props) => {
  // Structure of options is [{label: "", value: ""}, {label: "", value: ""}]
  const { label, options, selected, handleClick } = props;

  return (
    <StyledFormControl>
      <Select
        value={selected}
        onChange={handleClick}
        renderValue={() => (
          <Typography align="center" variant="h6" className={"roboto-thin"}>
            {label}
          </Typography>
        )}
        displayEmpty
        IconComponent={ExpandMore}
        sx={{
          ".MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input":
          {
            pl: 2,
            pr: 4.5,
          },
          margin: {xs: "8px", md: "-8px"},
        }}
      >
        {options &&
          options.map((option, index) => {
            return (
              <StyledMenuItem key={index} value={option.value}>
                <Typography className={"roboto-regular"}>
                  {option.label}
                </Typography>
              </StyledMenuItem>
            );
          })}
      </Select>
    </StyledFormControl>
  );
};

function splitArray(array, chunkSize) {
  const numOfChunks = Math.ceil(array.length / chunkSize);
  const result = [];

  for (let i = 0; i < numOfChunks; i++) {
    result.push(array.slice(i * chunkSize, (i + 1) * chunkSize));
  }

  return result;
}

function SearchPageLayout() {
  const DEFAULT_ID = 2;
  const classes = useStyles();
  const { id, subcategoryId } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [productCategoryData, setProductCategoryData] = useState({});
  const [menu, setMenu] = useState([]);
  const [categoryView, setCategoryView] = useState([]);
  const [originalProductList, setOriginalProductList] = useState([]);
  const [currentProductList, setCurrentProductList] = useState([]);
  const [currentProductGallery, setProductGallery] = useState([[]]);
  const [purposeSelected, setSelectedPurpose] = useState("");
  const [sortBySelected, setSortBySelected] = useState("");
  const [purposes, setPurposes] = useState([]);
  const [supportCarousel1Products, setSupportCarousel1Products] =
    useState([]);
  const [supportCarousel2Products, setSupportCarousel2Products] =
    useState([]);
  const [similarProductsData, setSimilarProducts] = useState([]);
  const [recentlyViewedProducts, setRecentlyViewed] = useState([]);
  // Temporary using this hook as a component toggle for categories
  const [signUpOption, setSignUpOption] = useState(false);
  const [searchedItem, setSearchedItem] = useState('');
  const [searchedProducts, setSearchedProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [originalSearchFoundNoProducts, setOriginalSearchFoundNoProducts] = useState(false);


  const [sustainabilityProducts, setSustainabilityProducts] = useState([[]]);
  const [communitySupportProducts, setCommunitySupportProducts] = useState([[]]);
  const [healthProducts, setHealthProducts] = useState([[]]);

  const [loadingSustainabilityProducts, setLoadingSustainabilityProducts] = useState(true);
  const [loadingCommunitySupportProducts, setLoadingCommunitySupportProducts] = useState(true);
  const [loadingHealthProducts, setLoadingHealthProducts] = useState(true);


  let query = useQuery();
  function useQuery() {
    const { search } = useLocation();
    let urlItems = `${search}`.split('?')
    urlItems.splice(0, 1);
    const itemObject = {}
    for (const item of urlItems) {
      itemObject[item.split('=')[0]] = item.split('=')[1];
    }
    return itemObject;
  }

  useEffect(() => {
    let rawSearchQuery = query["search"];
    let decodedSearchQuery = decodeURI(rawSearchQuery);
    setSearchedItem(decodedSearchQuery);
    if (searchedProducts.length < 1 && !loading && decodedSearchQuery) {
      setLoading(true);
      getProductsBySearch(decodedSearchQuery, (data) => {
        if (!data.hasOwnProperty("error") && !data.hasOwnProperty("message")) {
          if (data.length > 0) {
            setSearchedProducts(data);
            const filtPurposesArray = [];
            data.forEach(o => {
              o.purposes.forEach(purpose => {
                if (purpose.higherPurposeID == -1) {
                  if (!filtPurposesArray.some(p => p.label === purpose.purposeName)) {
                    filtPurposesArray.push({ label: purpose.purposeName, value: purpose.productPurposeID });
                  }
                }
              })
            });
            setPurposes(filtPurposesArray);
            setOriginalProductList(data);
            setCurrentProductList(data);
            setLoading(false);
          }
          else if (data.length === 0) {
            console.log('Search found no products!');
            setOriginalSearchFoundNoProducts(true);
            //get random preset search
            const randomPresetSearch = PRESET_SEARCHES[Math.floor(Math.random() * PRESET_SEARCHES.length)];
            getProductsBySearch(randomPresetSearch.id, (data2) => {
              setSearchedProducts(data2);
              const filtPurposesArray = [];
              data2.forEach(o => {
                o.purposes.forEach(purpose => {
                  if (purpose.higherPurposeID == -1) {
                    if (!filtPurposesArray.some(p => p.label === purpose.purposeName)) {
                      filtPurposesArray.push({ label: purpose.purposeName, value: purpose.productPurposeID });
                    }
                  }
                })
              });
              setPurposes(filtPurposesArray);
              setOriginalProductList(data2);
              setCurrentProductList(data2);
              setLoading(false);
            });
          }
        } else {
          console.log('Search had an error!');
          setLoading(false);
        }
      });
    }

    // Create purpose list on first render
    getListOfPurposeCategoriesById(purposesData, (data) => {
      let tempPurposes = [];
      data.forEach((purpose) => {
        tempPurposes.push({ label: purpose.name, value: purpose.id });
      });
      setPurposes(tempPurposes);
    });

    //Sustainability Products
    getPurposeCategoryById('60', (data) => {
      setSustainabilityProducts(splitArray(data, 12));
      setLoadingSustainabilityProducts(false);
    });

    //Community Support Products
    getPurposeCategoryById('100', (data) => {
      setCommunitySupportProducts(splitArray(data, 12));
      setLoadingCommunitySupportProducts(false);
    });

    //Health Products
    getPurposeCategoryById('80', (data) => {
      setHealthProducts(splitArray(data, 12));
      setLoadingHealthProducts(false);
    });


  }, []);

  // Update gallery pages whenever a filter causes the product list to update
  useGalleryPages(currentProductList, setProductGallery, 32);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <PurposeCategoryMasterLayout>
      <Divider />
      <Typography
        align="center"
        display="block"
        //className={classes.titleText}
        sx={{
          color: "var(--mintGreen)",
          fontSize: { xs: "3.3rem", sm: "4rem", md: "5rem" },
        }}
      >
        <b>
          {searchedItem}
        </b>
      </Typography>
      {originalSearchFoundNoProducts &&
        <Typography
          align="center"
          display="block"
          //className={classes.titleText}
          sx={{
            color: "var(--mintGreen)",
            fontSize: { xs: "2rem", sm: "2rem", md: "2rem" },
          }}
        >
          <b>
            {`Oops! Doesn’t look like we have that item yet. Please check some other trending items we know you’ll love below.`}
          </b>
        </Typography>
      }
      <>
        {!isMobile ? (
          <div className={classes.galleryViewDiv}>
            <Container maxWidth="xl">

              <DropdownButtonContainer>
                <DropdownButton
                  label={"Purposes"}
                  options={purposes}
                  selected={purposeSelected}
                  handleClick={(event) => {
                    const newPurpose = event.target.value;
                    setSelectedPurpose(newPurpose);
                    handlePurposeFilter(
                      newPurpose,
                      originalProductList,
                      setCurrentProductList
                    );
                  }}
                />
                <div style={{ width: 35 }} />
                <DropdownButton
                  label={"Sort By"}
                  options={[
                    // { label: "Most Popular", value: "popular" },
                    { label: "Price High to Low", value: "highToLow" },
                    { label: "Price Low to High", value: "lowToHigh" },
                    // { label: "Sale", value: "sale" },
                  ]}
                  selected={sortBySelected}
                  handleClick={(event) => {
                    const newValue = event.target.value;
                    setSortBySelected(newValue);
                    handleSortFilter(
                      newValue,
                      currentProductList,
                      setCurrentProductList
                    );
                  }}
                />
              </DropdownButtonContainer>
            </Container>
            <GalleryView
              productPages={currentProductGallery}
              showLoadingIcon={loading}
              itemsPerRow={6}
              extraPadding={true}
            />
          </div>
        ) : (
          <div className={classes.galleryViewDiv}>
            <DropdownButtonContainer>
              {/* <ButtonBase
                    onClick={handleClickPopover}
                    sx={{
                      marginRight: "auto",
                      display: "flex",
                      alignItems: "center",
                      borderRadius: "10px",
                    }}
                  >
                    <Typography
                      align="center"
                      className={"roboto-thin"}
                      sx={{ fontSize: 16 }}
                    >
                      Browse By
                    </Typography>
                    <ListIcon sx={{ fontSize: 30, ml: 1 }} />
                  </ButtonBase> */}
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Box sx={{ m: 1, p: 1 }}>
                  <CategoryMenu
                    categoryData={menu}
                    expandedCategory={subcategoryId ? subcategoryId : id}
                  />
                </Box>
              </Popover>
              <DropdownButton
                label={"Purposes"}
                options={purposes}
                selected={purposeSelected}
                handleClick={(event) => {
                  const newPurpose = event.target.value;
                  setSelectedPurpose(newPurpose);
                  handlePurposeFilter(
                    newPurpose,
                    originalProductList,
                    setCurrentProductList
                  );
                }}
              />
              {/* <div style={{ width: 10 }} /> */}
              <DropdownButton
                label={"Sort By"}
                options={[
                  // { label: "Most Popular", value: "popular" },
                  { label: "Price High to Low", value: "highToLow" },
                  { label: "Price Low to High", value: "lowToHigh" },
                  // { label: "Sale", value: "sale" },
                ]}
                selected={sortBySelected}
                handleClick={(event) => {
                  const newValue = event.target.value;
                  setSortBySelected(newValue);
                  handleSortFilter(
                    newValue,
                    currentProductList,
                    setCurrentProductList
                  );
                }}
              />
            </DropdownButtonContainer>
            <GalleryView
              mobile
              productPages={currentProductGallery}
              showLoadingIcon={loading}
              itemsPerRow={6}
              extraPadding={true}
            />
          </div>
        )}
      </>
      {/* {!signUpOption && <SignUp />} */}
      {/*<TrendiestView data={trendiestView} />*/}
      <div className={classes.divMarginBottom} />
      <BrandSupportsHeader
        title={'Our Common Home, Planet, Trees, and Oceans'}
        title_url={'/shop/purpose/60'}
      />
      <GalleryView
        productPages={sustainabilityProducts}
        itemsPerRow={6}
        extraPadding={true}
        showLoadingIcon={loadingSustainabilityProducts}
      />
      <BrandSupportsHeader
        title={'Our Community, Veterans, and More'}
        title_url={'/shop/purpose/100'}
      />
      <GalleryView
        productPages={communitySupportProducts}
        itemsPerRow={6}
        extraPadding={true}
        showLoadingIcon={loadingCommunitySupportProducts}
      />
      <BrandSupportsHeader
        title={'Mental and Physical Health Initiatives'}
        title_url={'/shop/purpose/80'}
      />
      <GalleryView
        productPages={healthProducts}
        itemsPerRow={6}
        extraPadding={true}
        showLoadingIcon={loadingHealthProducts}
      />
      <div className={classes.divMarginBottom} />
      <SupportYourView />
      {/* <Container maxWidth="xl">
        <Typography
          align="center"
          display="block"
          variant="h4"
          className={clsx(classes.carouselHeader, "roboto-bold")}
        >
          Similar Products
        </Typography>
      </Container>
      <CarouselProductView
        data={similarProductsData.length > 0 ? similarProductsData : null}
        hideSeller
        hidePurpose
      />
      <Container maxWidth="xl">
        <Typography
          align="center"
          display="block"
          variant="h4"
          className={clsx(classes.carouselHeader, "roboto-bold")}
        >
          Best Sellers
        </Typography>
      </Container>
      <CarouselProductView
        data={recentlyViewedProducts.length > 0 ? recentlyViewedProducts : null}
        hideSeller
        hidePurpose
      /> */}
      {/*<div className={classes.socialMedia}>
        <SocialMedia />
      </div>
      <OtherCategories otherCategories={otherCategories} />
      <div
        style={{
          height: "var(--dividerHeight)",
          backgroundColor: "var(--coral)",
        }}
      />*/}
      {/* <div style={{ height: "var(--dividerHeight)" }} /> */}
      {/*<Blog />*/}
      {/* <LoveInstagram /> */}
      {/* <div style={{ height: "var(--dividerHeight)" }} /> */}
    </PurposeCategoryMasterLayout>
  );
}

export default SearchPageLayout;
