import {
  Grid,
  Typography,
  Select,
  MenuItem,
  Avatar,
  Stack,
  FormControl,
  Modal,
  Box,
  Container,
  ButtonBase,
  Autocomplete,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  IconButton,
  InputLabel,
  OutlinedInput,
  Chip,
  useTheme,
  Button,
  Checkbox,
  InputAdornment
} from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import CloseIcon from '@mui/icons-material/Close';
import { createSitewideCoupon } from "../util/coupons";
import { COUPON_TYPES } from "../util/constants";
import toast, { Toaster } from 'react-hot-toast';

const style = {
  position: 'absolute',
  borderRadius: '16px',
  backgroundColor: "white",
  minWidth: "40%",
  maxHeight: "90vh",
  overflow: "auto",
  border: "2px solid",
  zIndex: "2",
  paddingTop: "32px",
  paddingBottom: "32px",
  boxShadow: 24,
};

const modalCenter = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const PromosCreateAdmin = (props) => {
  const { createPromoView, setCreatePromoView, productList } = props;
  const [formValues, setFormValues] = React.useState({
      percentage: "",
      discount: "",
      type: "Percent",
      startDate: "",
      foreverDuration: false,
      endBool: "",
      endDate: "",
      endTime: "",
      status: "",
      value: "",
      specificProducts: "false",
      specifiedProductsArr: [],
      limitBool: "false",
      limitVal: "",
      limitCustomers: false, 
      requireMin: false,
  });
  const { id: sellerIDparam } = useParams();

  const [isLoading, setIsLoading] = React.useState(false);

  const handleStartDateChange = (newValue) => {
      setFormValues((preValues) => {
          return { ...preValues, "startDate": newValue };
      });
  };

  const handleEndDateChange = (newValue) => {
      setFormValues((preValues) => {
          return { ...preValues, "endDate": newValue };
      });
  };

  const handleEndTimeChange = (newValue) => {
      setFormValues((preValues) => {
          return { ...preValues, "endTime": newValue };
      });
  };

  const handleFormValues = (name, value) => {
      setFormValues((preValues) => {
          return { ...preValues, [name]: value };
      });
  };

  const handleSubmit = async (e) => {
    if (!isLoading) {
      e.preventDefault();
      setIsLoading(true);

      let percentOff;
      let amountOff;
      if (formValues.type == 'Dollars Off') {
        amountOff = formValues.value;
        if (!amountOff || amountOff > 1000 || amountOff < 0) {
            toast.error('Amount off must be between 0 and $1,000');
            setIsLoading(false);
            return;
        }
    } else if (formValues.type == 'Percent') {
        percentOff = formValues.value;
        if (!percentOff || percentOff > 100 || percentOff < 0) {
            toast.error('Percent off must be between 0 and 100');
            setIsLoading(false);
            return;
        }
    }

      let maxRedemptions;
      if(formValues.limitBool == 'true') {
          maxRedemptions = formValues.limitVal
      }

      let expirationDate = null;
      //Date calculation
      if (!formValues.foreverDuration) {
        const endDate = new Date(formValues.endDate);
        const endTime = new Date(formValues.endTime);
  
        let newDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), endTime.getHours(), endTime.getMinutes(), endTime.getSeconds());
  
        expirationDate = newDate.getTime();
      }

      if ((!formValues.foreverDuration && !expirationDate) || !formValues.name || (!amountOff && !percentOff)) {
          toast.error('Must fill out all required fields!')
          setIsLoading(false);
          return;
      }

        //Good to create coupon
        createSitewideCoupon(maxRedemptions, COUPON_TYPES.PROMO_CODE, percentOff, amountOff, formValues.name, expirationDate, (data) => {
            if(data.success) {
                toast.success('Created promo code!')
                //setCreatePromoView(false);
                window.location.reload();
            } else {
                toast.error('Failed to create promo code!')
            }
            setIsLoading(false);
        });
    }
  }

  return (
      <Modal open={createPromoView} onClose={() => setCreatePromoView(false)} sx={modalCenter}>
          <>
            <Toaster 
                toastOptions={{
                className: '',
                style: {
                    border: '1px solid #713200',
                    padding: '16px',
                    color: '#713200',
                },
                }}
            />
            <Box sx={style}>
                <Container>
                    <Grid container justifyContent='space-between'>
                        <Typography variant="h5" style={{ color: "var(--coral)" }}>
                            <b>CREATE A PROMO CODE</b>
                        </Typography>
                        <IconButton
                            onClick={() => setCreatePromoView(false)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                    <Box sx={{ pt: 2 }}>
                        <Typography variant="h6">Promo Name</Typography>
                        <TextField
                            name="name"
                            onChange={(e) => handleFormValues("name", e.target.value)}
                            defaultValue={formValues.name}
                            variant="outlined"
                            size="small"
                            fullWidth
                        />
                        <Typography>May not contain spaces or special characters. This will appear on customers' receipts.</Typography>
                    </Box>
                    <Box sx={{ pt: 2 }}>
                        <Typography variant="h6">Terms of Promo Code</Typography>
                        <Stack direction={"row"} spacing={3}>
                            <TextField label="Enter a number value" onChange={(e) => handleFormValues("value", e.target.value)}></TextField>
                            <FormControl component="fieldset" sx={{ mb: 3 }}>
                                <RadioGroup
                                    row={true}
                                    name="type"
                                    onChange={(e) => handleFormValues("type", e.target.value)}
                                    value={formValues.type}
                                >

                                    <FormControlLabel
                                        value="Percent"
                                        control={
                                            <Radio
                                                style={{
                                                    color: "var(--coral)",
                                                    backgroundColor: "transparent",
                                                }}
                                            />
                                        }
                                        label="Percent"
                                    />
                                    {/* <FormControlLabel
                                        value="Dollars Off"
                                        control={
                                            <Radio
                                                style={{
                                                    color: "var(--coral)",
                                                    backgroundColor: "transparent",
                                                }}
                                            />
                                        }
                                        label="Dollars Off"
                                    /> */}
                                </RadioGroup>
                            </FormControl>
                        </Stack>
                    </Box>
                    <Box sx={{ pt: 2 }}>
                        <Typography variant="h6">Duration</Typography>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Stack
                                direction={"row"}
                                spacing={2}
                            >
                                <DatePicker
                                    label="Start Date"
                                    value={new Date()}
                                    disabled={true}
                                    onChange={handleStartDateChange}
                                    renderInput={(params) => <TextField {...params} fullWidth />}


                                />
                                {formValues.foreverDuration ? <></> : <>
                                <Typography style={{ display: "flex", alignItems: "center" }}>until</Typography>
                                <DatePicker
                                    label="End Date"
                                    value={formValues.endDate ? formValues.endDate : null}
                                    onChange={handleEndDateChange}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                />
                                <TimePicker
                                    label="Time"
                                    value={formValues.endTime ? formValues.endTime : null}
                                    onChange={handleEndTimeChange}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                /></>}
                            </Stack>
                        </LocalizationProvider>
                        <FormControlLabel
                            label="No end date."
                            control={<Checkbox checked={formValues.foreverDuration} onChange={(e) => handleFormValues("foreverDuration", e.target.checked)} sx={{
                            color: "var(--coral)",
                            '&.Mui-checked': {
                                color: "var(--coral)",
                            },
                            }}/>}
                        />
                        
                    </Box>
                    <Box sx={{ pt: 2 }}>
                        <Typography variant="h6">Limit the total number of times this Sale can be used.</Typography>
                        <FormControl component="fieldset" >
                            <RadioGroup row={true} name="limitBool" onChange={(e) => handleFormValues("limitBool", e.target.value)} value={formValues.limitBool}>
                                <FormControlLabel value="true"
                                    control={<Radio style={{color: "var(--coral)",backgroundColor: "transparent",}}/>}
                                    label="YES"
                                />
                                <FormControlLabel value="false"
                                    control={<Radio style={{color: "var(--coral)",backgroundColor: "transparent",}}/>}
                                    label="NO"
                                />
                            </RadioGroup>
                        </FormControl>
                        {formValues.limitBool === "true" ? 
                            <div style={{display: "flex", flexDirection: "row", alignItems: "center", columnGap: 16}}>
                            <TextField name="name"
                            label="Enter a number value"
                            onChange={(e) => handleFormValues("limitVal", e.target.value)}
                            defaultValue={formValues.limitVal}
                            variant="outlined"
                            /> <Typography>time&#40;s&#41;</Typography></div>: 
                        <Typography></Typography>}
                    </Box>
                    {/* <Stack sx={{ pt: 2, mb: 2 }}>
                        <Typography variant="h6">More options</Typography>
                        <FormControlLabel
                            label="Eligible for first time orders only."
                            control={<Checkbox sx={{color: "var(--coral)", '&.Mui-checked': {color: "var(--coral)",},}}/>}
                        />
                        <FormControlLabel
                            label="Limit to a specific customer. (one only)."
                            control={<Checkbox sx={{color: "var(--coral)", '&.Mui-checked': {color: "var(--coral)",},}} checked={formValues.limitCustomers} onChange={(e) => handleFormValues("limitCustomers", e.target.checked)}/>}
                        />
                        {formValues.limitCustomers && <TextField sx={{width: "280px", }} label="Enter a customer email." variant="outlined" />}
                        <FormControlLabel
                            label="Require a minimum order value."
                            control={<Checkbox sx={{color: "var(--coral)", '&.Mui-checked': {color: "var(--coral)",},}} checked={formValues.requireMin} onChange={(e) => handleFormValues("requireMin", e.target.checked)}/>}
                        />
                        {formValues.requireMin &&
                        <FormControl sx={{width: "280px", mt: 1}}>
                        <InputLabel htmlFor="Amount">Amount</InputLabel>
                        <OutlinedInput id="Amount" startAdornment={<InputAdornment position="start">$</InputAdornment>} label="Amount"/>
                        </FormControl>}
                    </Stack> */}
                    <br/>
                    <br/>
                    <br/>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", columnGap: 2 }}>
                        <Button variant="contained" sx={{backgroundColor: "darkgray"}} size="large"
                            onClick={(e)=> {
                                setCreatePromoView(false)
                            }}
                        >                            
                            <Typography>
                                <b>Cancel</b>
                            </Typography>
                        </Button>
                        <Button variant="contained" sx={{backgroundColor: "var(--coral)"}} size="large"
                            onClick={(e)=> {
                                handleSubmit(e);
                            }}
                            disabled={isLoading}
                        >
                            <Typography>
                                <b>{isLoading ? `Loading` : `Publish`}</b>
                            </Typography>
                        </Button>
                    </Box>
                </Container>
            </Box>
        </>
      </Modal>
  );
};

export default PromosCreateAdmin;