import {
  Grid,
  Typography,
  Stack,
  Box,
  Container,
  ButtonBase,
  Button,
  CircularProgress
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import useElementWidth from "../../hooks/useElementWidth";
import sellerDashboardJsonData from "../../json/seller_dashboard.json";
import React from "react";
import PromosView from "../../components/PromosView";
import { getSitewideCoupons } from "../../util/coupons";
import { COUPON_TYPES } from "../../util/constants";
import { formatDate } from "../../util/utilities";
import PromosCreateAdmin from "../../components/PromosCreateAdmin";
import AdminPortalManagementMaster from "../MasterLayouts/AdminPortalManagementMaster";

const useStyles = makeStyles({
  rightSectionWidth: {
    paddingLeft: 65,
    paddingRight: 65,
  },
  bubbleStatContainer: {
    paddingTop: 40,
    paddingBottom: 35,
  },
  bubbleStatFilter: {
    marginBottom: 30,
    fontSize: "1.25rem",
    "& .MuiInput-underline:after": {
      border: "2px solid #000000",
    },
  },
  bubbleAvatar: {
    width: "75%",
    margin: "0 auto",
    backgroundColor: "#F0F6F4",
    color: "#000000",
  },
});

const PromoTable = (props) => {
  const { promoView, setPromoView, setRow, createPromoView, setCreatePromoView, promos } = props;
  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="h4" sx={{ ml: 0 }} style={{ color: "#85b2a2", margin: "16px 0" }}>
          <b>Promo Codes</b>
        </Typography>
        <Button variant="contained" sx={{ backgroundColor: "var(--coral)" }} onClick={() => setCreatePromoView(true)}><b>+ Create a promo code</b></Button>
      </div>
      <Grid container >
        {/* <Grid xs={3} item sx={{ border: "gray solid 1px", py: 1 }}>
            <Typography variant="h5" align="center">
              <b>Current Sales</b>
            </Typography>
          </Grid>
          <Grid xs={9} item sx={{ border: "gray solid 1px" }}></Grid> */}
        <Grid
          xs={2}
          item
          sx={{
            borderLeft: "gray solid 1px",
            borderRight: "gray solid 1px",
            borderTop: "gray solid 1px",
            py: 1,
            bgcolor: "var(--lightGray)",
          }}
        >
          <Typography variant="h6" align="center">
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <b>Status</b>
            </Box>
          </Typography>
        </Grid>
        <Grid
          xs={3}
          item
          sx={{
            borderRight: "gray solid 1px",
            borderLeft: "gray solid 1px",
            borderTop: "gray solid 1px",
            py: 1,
            bgcolor: "var(--lightGray)",
          }}
        >
          <Typography variant="h6" align="center">
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <b>Name</b>
            </Box>
          </Typography>
        </Grid>
        <Grid
          xs={7}
          item
          sx={{ borderTop: "gray solid 1px", borderRight: "gray solid 1px", borderLeft: "gray solid 1px", bgcolor: "var(--lightGray)" }}
        >
          <Grid container >
            <Grid item xs={3} justifyContent="center">
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "8px" }}>
                <Typography variant="h6" style={{ textAlign: 'center' }}>
                  <b>Terms</b>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "8px" }}>
                <Typography variant="h6">
                  <b>Duration</b>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "8px" }}>
                <Typography variant="h6">
                  <b>Uses</b>
                </Typography>
              </Box>
            </Grid>
            {/* <Grid item xs={3}>
              <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "8px"}}>
                <Typography variant="h6">
                  <b>Revenue</b>
                </Typography>
                </Box>
              </Grid> */}
          </Grid>
        </Grid>

        {promos.map((promo, index) => {
          return (
            <>
              <Grid xs={2} item sx={{ border: "gray solid 1px", py: 1 }} key={index + promo.code + "STATUS"}
                onClick={() => { setRow(promo); setPromoView(true) }}
                component={ButtonBase}
              >
                <Stack spacing={3} sx={{ my: 2 }}>
                  <Typography align="center">
                    <b>{promo.active ? `ACTIVE` : `INACTIVE`}</b>
                  </Typography>
                </Stack>
              </Grid>
              <Grid xs={3} item sx={{ border: "gray solid 1px", py: 1 }} key={index + promo.code + "CODE"} onClick={() => { setRow(promo); setPromoView(true) }} component={ButtonBase}>
                <Stack spacing={3} sx={{ my: 2 }}>
                  <Typography align="center">
                    <b>{promo.couponCode}</b>
                  </Typography>
                  {/* <Typography variant="caption" align="center">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Manage
                  <ArrowForwardIosIcon sx={{ fontSize: 15 }} />
                </Box>
              </Typography> */}
                </Stack>
              </Grid>
              <Grid item xs={7} sx={{ border: "gray solid 1px" }} justifyContent="center" alignItems="center" onClick={() => { setRow(promo); setPromoView(true) }} component={ButtonBase}>
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item xs={3}>
                    <Stack spacing={3} sx={{ my: 3 }}>
                      <Typography align="center">
                        {promo.amountOff && !isNaN(promo.amountOff) && Number(promo.amountOff) > 0 ? `$${promo.amountOff} OFF` : `${promo.percentOff}% OFF`}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack spacing={3} sx={{ my: 3 }}>
                      <Typography align="center">
                        {`${formatDate(promo.startDateTime)} - ${formatDate(promo.expirationDateTime) || 'No End Date'}`}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={3}>
                    <Stack spacing={3} sx={{ my: 3 }}>
                      <Typography align="center">
                        {promo.redemptions}
                      </Typography>
                    </Stack>
                  </Grid>
                  {/* <Grid item xs={3}>
            <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "8px"}}>
              <Typography variant="h6">
                <b>{sale.revenue}</b>
              </Typography>
              </Box>
            </Grid> */}
                </Grid>
              </Grid>
            </>
          )
        })}


      </Grid>
    </>
  )
}

function SalesAndCouponsAdminLayout(props) {
  const classes = useStyles();
  const sellerDashboardData = sellerDashboardJsonData;
  const bubbleRef = React.useRef(null);
  const [bubbleStatFilter, setBubbleStatFiler] = React.useState("This Month");
  const bubbleWidth = useElementWidth(bubbleRef);
  const [row, setRow] = React.useState({});
  const [saleView, setSaleView] = React.useState(false);
  const [promoView, setPromoView] = React.useState(false);
  const [createSaleView, setCreateSaleView] = React.useState(false);
  const [createPromoView, setCreatePromoView] = React.useState(false);
  const [formValues, setFormValues] = React.useState({
    percentage: "",
    discount: "",
  });

  const [promos, setPromos] = React.useState([]);
  const [sales, setSales] = React.useState([]);
  const [loadingCoupons, setLoadingCoupons] = React.useState(true);

  React.useEffect(() => {
    getSitewideCoupons((data) => {
      data.sort((a, b) => { return new Date(b.startDateTime) - new Date(a.startDateTime) });
      setPromos(data.filter(c => c.couponType == COUPON_TYPES.PROMO_CODE));
      setSales(data.filter(c => c.couponType == COUPON_TYPES.SALE));
      setLoadingCoupons(false);
    });
  }, []);

  return (
    <AdminPortalManagementMaster
      title={"Sitewide Coupons"}
    >
      <>
        {loadingCoupons ?
          <Grid
            sx={{
              mt: 3,
              mb: 3,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress sx={{ color: "var(--coral)" }} size={150} />
          </Grid>
          :
          <>
            <PromosCreateAdmin createPromoView={createPromoView} setCreatePromoView={setCreatePromoView} />
            <PromosView productList={[]} promoView={promoView} setPromoView={setPromoView} promo={row} adminView={true} />
            <Container sx={{ my: 3, mx: 1 }} >
              <div style={{ height: "32px" }} />
              <PromoTable promos={promos} promoView={promoView} setPromoView={setPromoView} setRow={setRow} createPromoView={createPromoView} setCreatePromoView={setCreatePromoView} />
            </Container>
          </>
        }
      </>
    </AdminPortalManagementMaster>
  );
}

export default SalesAndCouponsAdminLayout;
