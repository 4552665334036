import { Avatar, Grid, Typography } from "@mui/material";
import mockData from "../json/bubble_category_data.json";
import React from "react";
import { getListOfProductCategoriesById } from "../util/productCategories";

const styles = {
  text: {
    margin: "15px auto",
  },
  imageAvatar: {
    width: "100%",
    height: "100%",
  },
  container: {
    width: "70%",
    margin: "auto",
    marginTop: 20,
  },
};

const Bubble = ({ item }) => (
  <Grid item xs>
    <a href={"/shop/category/" + item.id}>
      <div style={styles.container}>
        <Avatar
          style={styles.imageAvatar}
          src={item.img}
          alt={item.name}
        />
      </div>
      <Typography sx={styles.text} align="center" className={"roboto-medium"}>
        <b>{item.name}</b>
      </Typography>
    </a>
  </Grid>
);

const BestSellersCategoryBubbles = (props) => {
  const { data } = props;
  const [categoryBubbleData, setCategoryBubbleData] = React.useState([]);
  React.useEffect(() => {
    if (!data || data.length === 0) {
      getListOfProductCategoriesById(mockData, (retrievedData) => {
        if (
          !retrievedData.hasOwnProperty("error") &&
          !retrievedData.hasOwnProperty("message")
        ) {
          setCategoryBubbleData(retrievedData);
        }
      });
    } else {
      setCategoryBubbleData(data);
    }
  }, [data]);

  return (
    <div style={{ margin: "30px auto" }}>
      <Grid container direction="row" rowSpacing={1} justifyContent='center'>
        {categoryBubbleData.slice(0, 5).map((item, i) => {
          return (
            <Grid item xs={4} md={2}>
              <Bubble key={i} item={item} />
            </Grid>);
        })}
      </Grid>
    </div>
  );
};

export default BestSellersCategoryBubbles;
