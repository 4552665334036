import { useEffect } from 'react';

export default function useCallRailScript() {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = "//cdn.callrail.com/companies/646090903/de8b1a6fa570769d44fe/12/swap.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);
};