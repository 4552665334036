import { useEffect } from 'react';

const useFacebookPixel = () => {
  const PIXEL_ID = '835772521020638';
  
  useEffect(() => {
    // Check if fbq is already initialized
    if (window.fbq) {
      return;
    }
    
    // Initialize the fbq function
    window.fbq = function() {
      window.fbq.callMethod ?
        window.fbq.callMethod.apply(window.fbq, arguments) :
        window.fbq.queue.push(arguments);
    };
    
    window.fbq.push = window.fbq;
    window.fbq.loaded = true;
    window.fbq.version = '2.0';
    window.fbq.queue = [];
    
    // Add the Facebook Pixel script to the DOM
    const fbPixelScript = document.createElement('script');
    fbPixelScript.async = true;
    fbPixelScript.src = `https://connect.facebook.net/en_US/fbevents.js`;
    document.head.appendChild(fbPixelScript);
    
    // After the script loads, initialize and track a page view
    fbPixelScript.onload = () => {
      window.fbq('init', PIXEL_ID);
      window.fbq('track', 'PageView');
    }

    // Add the Facebook Pixel noscript image to the DOM
    const fbPixelNoScript = document.createElement('noscript');
    const img = document.createElement('img');
    img.src = `https://www.facebook.com/tr?id=${PIXEL_ID}&ev=PageView&noscript=1`;
    img.height = 1;
    img.width = 1;
    img.style.display = 'none';
    fbPixelNoScript.appendChild(img);
    document.body.appendChild(fbPixelNoScript);
  }, [PIXEL_ID]);
};

export default useFacebookPixel;
