import { Typography, styled, Container } from "@mui/material";
import CarouselProductView from "./CarouselProductView";
import defaultData from "../json/brand_supports.json";
import useMobileMediaQuery from "../hooks/useMobileMediaQuery";
import BrandSupportsHeader from "./BrandSupportsHeader";

const BrandSupports = ({
  data = defaultData,
  title = "POVERTY & HOMELESSNESS SOLUTIONS",
  title_url = "#",
  hideSeller = false,
  hidePurpose = false,
}) => {
  const mobile = useMobileMediaQuery();
  return (
    <>
      <BrandSupportsHeader
        title={title}
        title_url={title_url}
      />
      <CarouselProductView
        data={data}
        hideSeller={hideSeller}
        hidePurpose={hidePurpose}
      />
    </>
  );
};

export default BrandSupports;
