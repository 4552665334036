import { useRef, useState, forwardRef } from "react";
import { AdminPortalManagementMaster } from "..";
import sellerDashboardJsonData from "../../json/seller_dashboard.json";
import {
  Container,
  Box,
  Typography,
  Grid,
  Modal,
  CircularProgress,
  Button,
  Stack,
  ButtonBase,
} from "@mui/material";
import { useHistory, Link, useParams } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import 'react-markdown-editor-lite/lib/index.css';
import FileUploadV2 from "../../components/FileUploadV2";
import { uploadImageArray } from "../../util/images";

const ContentRow = ({ title, children }) => {
  return (
    <Grid container style={{ padding: "16px 32px 0px 16px" }}>
      <Grid item xs={12}>
        <Typography variant="h5" style={{ color: "var(--coral)" }}>
          <b>{title}</b>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};

function AddPictureLayout() {
  const sellerDashboardData = sellerDashboardJsonData;
  const [formValues, setFormValues] = useState({
    pictures: [],
  });

  const [isLoading, setIsLoading] = useState(false);
  
  const handleFormValues = (name, value) => {
    setFormValues((preValues) => {
      return { ...preValues, [name]: value };
    });
  };
  
  const uploadImagesAndUpdateFormData = async (imageArray) => {
    setIsLoading(true);
    const imgUrlArray = await uploadImageArray(imageArray);
    handleFormValues('pictures', imgUrlArray);
    setIsLoading(false);
    return imgUrlArray;
  }

  return (
    <AdminPortalManagementMaster
      title="Create New Blog Post"
      sellerName={sellerDashboardData.name}
      sellerPicture={sellerDashboardData.pic}
    >
      <Toaster
        toastOptions={{
          className: '',
          style: {
            border: '1px solid #713200',
            padding: '16px',
            color: '#713200',
          },
        }}
      />
      <Container maxWidth={"xl"}>
        <ContentRow title={"Upload Pictures"}>
          <Container maxWidth="sm">
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              rowSpacing={2}
              columnSpacing={2}
              sx={{ mt: 1 }}
            >
              <FileUploadV2
                formValueName={"pictures"}
                formValueArray={formValues.pictures}
                maxFiles={3}
                columns={3}
                handleFormValues={handleFormValues}
                buttonText={'upload: jpg, png'}
              />
              <Typography variant="caption">
                Upload 3 images, the first being the banner photo and the other 2 being smaller photos at the bottom of the blog.
              </Typography>
              <br/>
              <br/>
              <br/>
              <Button
                variant="contained"
                onClick={() => uploadImagesAndUpdateFormData(formValues.pictures ?? [])}
                sx={{
                  bgcolor: "var(--coral)",
                }}
              >
                {`Upload`}
              </Button>
            </Grid>
          </Container>
        </ContentRow>
      </Container>
      <Container maxWidth={"xl"}>
        <ContentRow title={formValues.pictures.filter(i => i.includes('cloudfront.net')).length > 0 ? "Pictures" : ""}>
          <Container maxWidth="sm">
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              rowSpacing={2}
              columnSpacing={2}
              sx={{ mt: 1 }}
            > 
              {formValues.pictures.filter(i => i.includes('cloudfront.net')).map((imageUrl, index) => (
                <>
                  <img key={index} src={imageUrl} alt={`image ${index}`} style={{ maxWidth: "350px", height: "auto" }} />
                  <Typography variant="caption">
                    {imageUrl}
                  </Typography>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                </>
              ))}
            </Grid>
          </Container>
        </ContentRow>
      </Container>
      {/* Temporary loading screen while listing is being uploaded */}
      <Modal open={isLoading} sx={{ overflow: "scroll" }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: 2,
            bgcolor: "background.paper",
            p: 4,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress sx={{ color: "var(--coral)" }} size={150} />
        </Box>
      </Modal>
    </AdminPortalManagementMaster>
  );
}

export default AddPictureLayout;
