import { Container, Divider, Grid, Typography } from "@mui/material";
import Blog from "../../components/Blog";
import CategoryMenu from "../../components/CategoryMenu";
import GalleryView from "../../components/GalleryView";
import { PurposeCategoryMasterLayout } from "../../layouts";

import recentlyViewedJson from "../../json/BestSellers/recently_viewed_best_sellers.json";
import categoryJsonData from "../../json/category_menu.json";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import CarouselProductView from "../../components/CarouselProductView";
import BestSellersCategoryBubbles from "../../components/BestSellersCategoryBubbles";
import FeaturedProductsAndSpecials from "../../components/FeaturedProductsAndSpecials";
import React from "react";
import { getListOfProductCategoriesById } from "../../util/productCategories";
import { getListOfProductsById } from "../../util/getProductsById";

const styles = {
  titleText: {
    marginTop: "20px",
    fontSize: "calc(20px + 3.5vw)",
    color: "var(--mintGreenSolid)",
  },
  categoryMenu: {
    marginTop: "20px",
    marginLeft: "10px",
  },
  categoryViewGalleryGridItem: {
    margin: "auto",
  },
  galleryViewDiv: {
    marginBottom: "50px",
  },
  carouselHeader: {
    fontSize: "2rem",
    marginTop: "50px",
    marginBottom: "30px",
  },
};

// Use Category Layout to style this page.
const BestSellersLayout = () => {
  const [categoryMenuData, setCategoryMenuData] = React.useState([]);
  const [recentlyViewed, setRecentlyViewed] = React.useState([]);

  React.useEffect(() => {
    const categories = categoryJsonData.categories;

    getListOfProductCategoriesById(
      categories,
      (data) => {
        if (!data.hasOwnProperty("error") && !data.hasOwnProperty("message")) {
          data.sort((category1, category2) => {
            return category1.id - category2.id;
          });
          setCategoryMenuData(data);
        }
      },
      true
    );

    getListOfProductsById(recentlyViewedJson, (data) => {
      if (!data.hasOwnProperty("error") && !data.hasOwnProperty("message")) {
        setRecentlyViewed(data);
      }
    });
  }, []);

  useDocumentTitle("All Best-Sellers");

  return (
    <PurposeCategoryMasterLayout>
      <Divider />
      <Container maxWidth="xl">
        <Typography align="center" display="block" sx={styles.titleText}>
          <b>{"All Best-Sellers"}</b>
        </Typography>
        <BestSellersCategoryBubbles />
        <Grid container direction="row" spacing={12}>
          <Grid item xs={12} sm={12} md={3} sx={styles.categoryMenu}>
            <CategoryMenu
              categoryData={categoryMenuData}
              expandedCategory={0}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={8} sx={styles.categoryViewGalleryGridItem}>
            <div style={styles.galleryViewDiv}>
              <GalleryView />
            </div>
          </Grid>
        </Grid>
        <FeaturedProductsAndSpecials />
        <Typography align="center" display="block" sx={styles.carouselHeader}>
          <b>Best Sellers</b>
        </Typography>
        <CarouselProductView data={recentlyViewed} />
      </Container>
      
      <div style={{ height: "var(--dividerHeight)" }} />
      <Blog />
      <div
        style={{
          height: "var(--dividerHeight)",
        }}
      />
    </PurposeCategoryMasterLayout>
  );
};

export default BestSellersLayout;
