import {
  Typography,
  Grid,
  Divider,
  Container,
  styled,
  FormControl,
  outlinedInputClasses,
  selectClasses,
  svgIconClasses,
  Select,
  MenuItem,
  menuItemClasses,
  useMediaQuery,
  useTheme,
  Avatar,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useRef } from "react";
import CarouselProductView from "../../components/CarouselProductView";
import { useParams } from "react-router-dom";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { PurposeCategoryMasterLayout } from "..";
import BrandSupports from "../../components/BrandSupports";
import SupportYourView from "../../components/SupportYourView";
import { getListOfProductsById } from "../../util/getProductsById";
import useGalleryPages from "../../hooks/useGalleryPages";
import clsx from "clsx";
import LoveInstagram from "../../components/LoveInstagram";
import purposesData from "../../json/purposes.json";
import { getListOfPurposeCategoriesById } from "../../util/purposeCategories";
import {
  getListOfProductCategoriesById,
  getProductCategoryById,
} from "../../util/productCategories";
import { useLocation, Link } from "react-router-dom";
import { getSellersBySupportedCountry } from "../../util/sellers";
import useElementWidth from "../../hooks/useElementWidth";


const useStyles = makeStyles({
  titleText: {
    fontSize: "5rem",
    color: "var(--mintGreen)",
  },
  subtitleText: {
    fontSize: "1.563rem",
    color: "var(--mintGreen)",
    marginBottom: 50,
  },
  categoryMenu: {
    marginTop: 20,
    marginLeft: 10,
  },
  categoryViewGalleryGridItem: {
    margin: "0 auto",
  },
  browseByText: {
    fontSize: "1.25rem",
  },
  categoryViewDiv: {
    marginBottom: 70,
    margin: "auto",
  },
  galleryViewDiv: {
    marginBottom: 50,
  },
  carouselHeader: {
    marginTop: 50,
    marginBottom: 30,
  },
  socialMedia: {
    marginTop: 50,
    marginBottom: 90,
  },
  container: {
    // paddingRight: 95,
    alignItems: "center",
  },
  avatar: {
    margin: "auto",
  },
  avatarDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderStyle: "solid",
    borderColor: "#000000",
    borderRadius: "50%",
    borderWidth: 1,
    margin: "auto",
  },
  contactSellerDiv: {
    textAlign: "center",
  },
  contactSellerImg: {
    margin: "auto",
    width: 74.391,
    height: 73.594,
  },
  bioText: {
    marginTop: 35,
  },
  rightGridItem: {
    paddingLeft: 10,
  },
  moreButtonGrid: {
    marginTop: 10,
  },
  moreButtonCursor: {
    cursor: "pointer",
  },
  editButtonDiv: {
    display: "flex",
    justifyContent: "flex-end",
    position: "relative",
    bottom: 45,
  },
  editButton: {
    color: "#FFFFFF",
    backgroundColor: "var(--coral)",
    textTransform: "none",
    paddingLeft: 15,
    paddingRight: 15,
    "&:hover": {
      backgroundColor: "var(--coral)",
    },
  },
  editButtonText: {
    marginLeft: 10,
  },
});

const DropdownButtonContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
});

const StyledFormControl = styled(FormControl)({
  [`& .${outlinedInputClasses.root}`]: {
    height: 40,
    borderRadius: 7,
    [`& .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: "#000000",
    },
    [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
      {
        borderWidth: 1,
      },
    [`& .${selectClasses.select}`]: {
      paddingRight: 0,
      paddingLeft: 27,
    },
  },
  [`& .${svgIconClasses.root}`]: {
    paddingRight: 0,
  },
});

const StyledMenuItem = styled(MenuItem)({
  backgroundColor: "#FFFFFF",
  [`&.${menuItemClasses.selected}`]: {
    backgroundColor: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
  },
});

const StoreName = styled(Link)({
  color: "var(--mintGreen)",
});


const DropdownButton = (props) => {
  // Structure of options is [{label: "", value: ""}, {label: "", value: ""}]
  const { label, options, selected, handleClick } = props;

  return (
    <StyledFormControl>
      <Select
        value={selected}
        onChange={handleClick}
        renderValue={() => (
          <Typography align="center" variant="h6" className={"roboto-thin"}>
            {label}
          </Typography>
        )}
        displayEmpty
        IconComponent={ExpandMore}
        sx={{
          ".MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input":
            {
              pl: 2,
              pr: 4.5,
            },
        }}
      >
        {options &&
          options.map((option, index) => {
            return (
              <StyledMenuItem key={index} value={option.value}>
                <Typography className={"roboto-regular"}>
                  {option.label}
                </Typography>
              </StyledMenuItem>
            );
          })}
      </Select>
    </StyledFormControl>
  );
};

function SearchSellerByCountryPageLayout() {
  const DEFAULT_ID = 2;
  const classes = useStyles();
  const { id, subcategoryId } = useParams();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const circleGridRef = useRef(null);
  const circleGridWidth = useElementWidth(circleGridRef);
  const [productCategoryData, setProductCategoryData] = React.useState({});
  const [menu, setMenu] = React.useState([]);
  const [categoryView, setCategoryView] = React.useState([]);
  const [originalProductList, setOriginalProductList] = React.useState([]);
  const [currentProductList, setCurrentProductList] = React.useState([]);
  const [currentProductGallery, setProductGallery] = React.useState([[]]);
  const [purposeSelected, setSelectedPurpose] = React.useState("");
  const [sortBySelected, setSortBySelected] = React.useState("");
  const [purposes, setPurposes] = React.useState([]);
  const [supportCarousel1Products, setSupportCarousel1Products] =
    React.useState([]);
  const [supportCarousel2Products, setSupportCarousel2Products] =
    React.useState([]);
  const [similarProductsData, setSimilarProducts] = React.useState([]);
  const [recentlyViewedProducts, setRecentlyViewed] = React.useState([]);
  // Temporary using this hook as a component toggle for categories
  const [signUpOption, setSignUpOption] = React.useState(false);
  const [searchedItem, setSearchedItem] = React.useState('');
  const [searchedProducts, setSearchedProducts] = React.useState([]);
  const [searchedSellers, setSearchedSellers] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  let query = useQuery();
  function useQuery() {
    const { search } = useLocation();
    let urlItems = `${search}`.split('?')
    urlItems.splice(0,1);
    const itemObject = {}
    for (const item of urlItems) {
      itemObject[item.split('=')[0]] = item.split('=')[1];
    }
    return itemObject;
  }

  React.useEffect(() => {
    // Fetches product category data
    const isSubcategory = 5; //subcategoryId !== undefined;
    const requestId = isSubcategory ? subcategoryId : id;
    if (requestId === undefined) {
      return;
    }
    getProductCategoryById(requestId, (data) => {
      if (!data.hasOwnProperty("error") && !data.hasOwnProperty("message")) {
        if (Object.keys(data["legacyLayoutData"]).length === 0) {
          // No layout data
          if (isSubcategory) {
            // Fetch parent category layout
            getProductCategoryById(id, (parentData) => {
              if (
                !parentData.hasOwnProperty("error") &&
                !data.hasOwnProperty("message") &&
                parentData.hasOwnProperty("legacyLayoutData")
              ) {
                data["legacyLayoutData"] = parentData["legacyLayoutData"];
                data["subcategories"] = parentData["subcategories"];
                setProductCategoryData(data);
              } else {
                // Fetch default
                getProductCategoryById(DEFAULT_ID, (defaultData) => {
                  if (
                    !defaultData.hasOwnProperty("error") &&
                    !data.hasOwnProperty("message")
                  ) {
                    data["legacyLayoutData"] = defaultData["legacyLayoutData"];
                    data["subcategories"] = parentData["subcategories"];
                    setProductCategoryData(data);
                  }
                });
              }
            });
          } else {
            // Fetch default
            getProductCategoryById(DEFAULT_ID, (defaultData) => {
              if (
                !defaultData.hasOwnProperty("error") &&
                !data.hasOwnProperty("message")
              ) {
                data["legacyLayoutData"] = defaultData["legacyLayoutData"];
                data["subcategories"] = defaultData["subcategories"];
                setProductCategoryData(data);
              }
            });
          }
        } else {
          setProductCategoryData(data);
        }
      } else {
        // Fetch default
        getProductCategoryById(DEFAULT_ID, (defaultData) => {
          if (
            !defaultData.hasOwnProperty("error") &&
            !data.hasOwnProperty("message")
          ) {
            setProductCategoryData(defaultData);
          }
        });
      }
    });
  }, [id, subcategoryId]);

  React.useEffect(() => {
    // Create purpose list on first render
    getListOfPurposeCategoriesById(purposesData, (data) => {
      let tempPurposes = [];
      data.forEach((purpose) => {
        tempPurposes.push({ label: purpose.name, value: purpose.id });
      });
      setPurposes(tempPurposes);
    });
  }, []);

  React.useEffect(() => {
    let rawSearchQuery = query["country"];
    let decodedSearchQuery = decodeURI(rawSearchQuery);
    setSearchedItem(decodedSearchQuery);
    if (searchedSellers.length < 1 && !loading && decodedSearchQuery) {
      setLoading(true);
      getSellersBySupportedCountry(decodedSearchQuery, (data) => {
        if (!data.hasOwnProperty("error") && !data.hasOwnProperty("message")) {
          setSearchedSellers(data);
        } else {
          console.log('Search found no sellers!');
        }
        setLoading(false);
      });
    }
  }, []);

  React.useEffect(() => {
    if (
      productCategoryData.hasOwnProperty("subcategories") &&
      productCategoryData.hasOwnProperty("name") &&
      productCategoryData.subcategories.length > 0
    ) {
      // Constrcut category menu
      getListOfProductCategoriesById(
        productCategoryData.subcategories,
        (data) => {
          if (
            !data.hasOwnProperty("error") &&
            !data.hasOwnProperty("message")
          ) {
            productCategoryData.subcategories = data;
            // console.log([productCategoryData]);
            setMenu([productCategoryData]);
          }
        }
      );
    }

    if (productCategoryData.hasOwnProperty("legacyLayoutData")) {
      const layoutData = productCategoryData.legacyLayoutData;

      if (layoutData.hasOwnProperty("categoryView")) {
        getListOfProductCategoriesById(layoutData.categoryView, (data) => {
          if (
            !data.hasOwnProperty("error") &&
            !data.hasOwnProperty("message")
          ) {
            setCategoryView(data);
          }
        });
      }

      if (layoutData.hasOwnProperty("productGallery")) {
        // Transform productGallery from guids into product data
        getListOfProductsById(
          layoutData.productGallery,
          (transformedProductGallery) => {
            if (
              !transformedProductGallery.hasOwnProperty("error") &&
              !transformedProductGallery.hasOwnProperty("message")
            ) {
              setOriginalProductList(transformedProductGallery);
              setCurrentProductList(transformedProductGallery);
            }
          }
        );
      }

      // Transform other product carousels
      if (layoutData.hasOwnProperty("supportCarousel1")) {
        getListOfProductsById(layoutData.supportCarousel1.products, (data) => {
          if (
            !data.hasOwnProperty("error") &&
            !data.hasOwnProperty("message")
          ) {
            setSupportCarousel1Products(data);
          }
        });
      }

      if (layoutData.hasOwnProperty("supportCarousel2")) {
        getListOfProductsById(layoutData.supportCarousel2.products, (data) => {
          if (
            !data.hasOwnProperty("error") &&
            !data.hasOwnProperty("message")
          ) {
            setSupportCarousel2Products(data);
          }
        });
      }

      if (layoutData.hasOwnProperty("similarProducts")) {
        getListOfProductsById(layoutData.similarProducts, (data) => {
          if (
            !data.hasOwnProperty("error") &&
            !data.hasOwnProperty("message")
          ) {
            setSimilarProducts(data);
          }
        });
      }

      if (layoutData.hasOwnProperty("recentlyViewed")) {
        getListOfProductsById(layoutData.recentlyViewed, (data) => {
          if (
            !data.hasOwnProperty("error") &&
            !data.hasOwnProperty("message")
          ) {
            setRecentlyViewed(data);
          }
        });
      }

      setSignUpOption(
        layoutData.hasOwnProperty("signUpToggle") && layoutData.signUpToggle
      );
    }
  }, [id, productCategoryData]);

  // Update gallery pages whenever a filter causes the product list to update
  useGalleryPages(currentProductList, setProductGallery, 32);

  //Changes Title based on Category chosen
  useDocumentTitle(
    productCategoryData.hasOwnProperty("name")
      ? productCategoryData.name + " Category"
      : "Purposer"
  );

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <PurposeCategoryMasterLayout>
      <Divider />
      <Container maxWidth="xl">
        <Typography
          align="center"
          display="block"
          //className={classes.titleText}
          sx={{
            color: "var(--mintGreen)",
            fontSize: { xs: "3.3rem", sm: "4rem", md: "5rem" },
          }}
        >
          <b>
            {`Stores that support "${searchedItem}"`}
          </b>
        </Typography>
        {!loading && searchedSellers.length > 0 && 
          <Container maxWidth="xl" className={classes.container}>
            {searchedSellers?.map((seller) => {
                return (
                  <Grid container direction="row" alignItems={"center"} rowSpacing={3}>
                    <Grid item xs={7} md={2} ref={circleGridRef}>
                      {/* <div
                        className={classes.avatarDiv}
                        style={{
                          width: 0.8 * circleGridWidth,
                          height: 0.8 * circleGridWidth,
                        }}
                      > */}
                        <Avatar
                          src={seller.profile_pic}
                          className={classes.avatar}
                          // style={{
                          //   width: 0.8 * circleGridWidth,
                          //   height: 0.8 * circleGridWidth,
                          // }}
                        />
                      {/* </div> */}
                    </Grid>
                    <Grid item xs={7} md={4}>
                      <StoreName
                        sx={{ typography: { xs: "h4", sm: "h3" } }}
                        className={"roboto-bold"}
                        to={`/shop/seller/${seller.sellerID}/${seller.sellerName?.replace(/\W+/g, "-")}`}
                      >
                        {seller.sellerName}
                      </StoreName>
                    </Grid>
                  </Grid>
                )
              })
            }
          </Container>
        }
      </Container>
      <SupportYourView />
      {/* {!signUpOption && <SignUp />} */}
      {/*<TrendiestView data={trendiestView} />*/}
      <div className={classes.divMarginBottom} />
      <BrandSupports
        title={
          productCategoryData.hasOwnProperty("legacyLayoutData") &&
          productCategoryData.legacyLayoutData.hasOwnProperty(
            "supportCarousel1"
          )
            ? productCategoryData.legacyLayoutData.supportCarousel1.header
            : ""
        }
        data={supportCarousel1Products}
        title_url={
          productCategoryData.hasOwnProperty("legacyLayoutData") &&
          productCategoryData.legacyLayoutData.hasOwnProperty(
            "supportCarousel1"
          )
            ? productCategoryData.legacyLayoutData.supportCarousel1
                .useMobileMediaQuery
            : ""
        }
        hideSeller
        hidePurpose
      />
      <BrandSupports
        title={
          productCategoryData.hasOwnProperty("legacyLayoutData") &&
          productCategoryData.legacyLayoutData.hasOwnProperty(
            "supportCarousel2"
          )
            ? productCategoryData.legacyLayoutData.supportCarousel2.header
            : ""
        }
        data={supportCarousel2Products}
        title_url={
          productCategoryData.hasOwnProperty("legacyLayoutData") &&
          productCategoryData.legacyLayoutData.hasOwnProperty(
            "supportCarousel2"
          )
            ? productCategoryData.legacyLayoutData.supportCarousel2
                .useMobileMediaQuery
            : ""
        }
        hideSeller
        hidePurpose
      />
      <Container maxWidth="xl">
        <Typography
          align="center"
          display="block"
          variant="h4"
          className={clsx(classes.carouselHeader, "roboto-bold")}
        >
          Similar Products
        </Typography>
      </Container>
      <CarouselProductView
        data={similarProductsData.length > 0 ? similarProductsData : null}
        hideSeller
        hidePurpose
      />
      <Container maxWidth="xl">
        <Typography
          align="center"
          display="block"
          variant="h4"
          className={clsx(classes.carouselHeader, "roboto-bold")}
        >
          Best Sellers
        </Typography>
      </Container>
      <CarouselProductView
        data={recentlyViewedProducts.length > 0 ? recentlyViewedProducts : null}
        hideSeller
        hidePurpose
      />
      {/*<div className={classes.socialMedia}>
        <SocialMedia />
      </div>
      <OtherCategories otherCategories={otherCategories} />
      <div
        style={{
          height: "var(--dividerHeight)",
          backgroundColor: "var(--coral)",
        }}
      />*/}
      <div style={{ height: "var(--dividerHeight)" }} />
      {/*<Blog />*/}
      <LoveInstagram />
      <div style={{ height: "var(--dividerHeight)" }} />
    </PurposeCategoryMasterLayout>
  );
}

export default SearchSellerByCountryPageLayout;
