import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import CarouselProductView from "./CarouselProductView";
import CarouselView from "./CarouselView";
import CategoryView from "./CategoryView";
import PurposeCategoryView from "./PurposeCategoryView";
import RelatedCategoriesAndSearches from "./RelatedCategoriesAndSearches";
import ProductGalleryPreview from "./ProductGalleryPreview";
import PurchaseView from "./PurchaseView";
import mockData from "../json/mockAPIdata.json";

import Blog from "./Blog";
import mockBlogData from "../json/blog_posts.json";
import SocialMedia from "./SocialMedia";
import Footer from "./Footer";
import LegalFooter from "./LegalFooter";
import GalleryView from "./GalleryView";
import mockData2 from "../json/api_data2.json";
import Reviews from "./Reviews";
import mockReviewData from "../json/reviews.json";
import { getReviewsByProduct } from "../util/products";

import RelatedPurchases from "./RelatedPurchases";
import mockRelatedPurchasesData from "../json/related_purchases.json";
import OtherInterests from "./OtherInterests";
import MockOtherInterestsData from "../json/other_interests.json";
import CategoryMenu from "./CategoryMenu";
import categoryMenuData from "../json/category_menu.json";
import KeepUp from "./KeepUp";
import SignIn from "./SignIn";
import ToolsAndTips from "./ToolsAndTips";
import toolsAndTipsData from "../json/tools_and_tips.json";
import MarketingAndSales from "./MarketingAndSales";
import marketingAndSalesData from "../json/marketing_and_sales.json";
import ProductPhotoGuideline from "./ProductPhotoGuideline";
import productPhotoGuidelineData from "../json/product_photo_guideline.json";
import Forum from "./Forum";
import forumData from "../json/forum.json";
import AddedToCart from "./AddedToCart";
import addedToCartData from "../json/added_to_cart.json";
import CartSubtotal from "./CartSubtotal";
import cartSubtotalData from "../json/cart_subtotal.json";
import Favorites from "./Favorites";
import FavoritesSearched from "./FavoritesSearched";
import SiteHeader from "./SiteHeader";
import DropDownButton from "./DropDownButton";
import SellerCarouselBanner from "./SellerCarouselBanner";
import sellerCarouselBannerData from "../json/seller_carousel_banner.json";

import MyPurpose from "./MyPurpose";
import myPurposeData from "../json/my_purpose.json";
import SellerHeader from "./SellerHeader";
import PurposeCarousel from "./PurposeCarousel";
import FeaturedProductsAndSpecials from "./FeaturedProductsAndSpecials";
import InAction from "./InAction";
import OpenAShopStepper from "./OpenAShopStepper";
import SellerSuccessStories from "./SellerSuccessStories";
import ManagementMenu from "./ManagementMenu";
import SellerStatsChart from "./SellerStatsChart";
import OrderNumberTable from "./OrderNumberTable";
import NotificationTable from "./NotificationTable";

export default function ComponentsPage() {
  return (
    <div>
      <Router>
        <div>
          {/*<SiteHeader></SiteHeader>*/}
          <nav>
            <ul>
              <li>
                <Link to="/component_lib/blog">Blog</Link>
              </li>
              <li>
                <Link to="/component_lib/siteheader">Site Header</Link>
              </li>
              <li>
                <Link to="/component_lib/dropdownbutton">Drop Down Button</Link>
              </li>
              <li>
                <Link to="/component_lib/socialmedia">Social Media</Link>
              </li>
              <li>
                <Link to="/component_lib/footer">Footer</Link>
              </li>
              <li>
                <Link to="/component_lib/legalfooter">Legal Footer</Link>
              </li>
              <li>
                <Link to="/component_lib/galleryview">Gallery View</Link>
              </li>
              <li>
                <Link to="/component_lib/reviews">Reviews</Link>
              </li>
              <li>
                <Link to="/component_lib/relatedpurchases">
                  Related Purchases
                </Link>
              </li>
              <li>
                <Link to="/component_lib/otherinterests">Other Interests</Link>
              </li>
              <li>
                <Link to="/component_lib/categorymenu">Category Menu</Link>
              </li>
              <li>
                <Link to="/component_lib/keepup">Keep Up</Link>
              </li>
              <li>
                <Link to="/component_lib/signin">Sign In</Link>
              </li>
              <li>
                <Link to="/component_lib/toolsandtips">Tools and Tips</Link>
              </li>
              <li>
                <Link to="/component_lib/marketingandsales">
                  Marketing And Sales
                </Link>
              </li>
              <li>
                <Link to="/component_lib/productphotoguideline">
                  Product Photo Guideline
                </Link>
              </li>
              <li>
                <Link to="/component_lib/forum">Forum</Link>
              </li>
              <li>
                <Link to="/component_lib/addedtocart">Added To Cart</Link>
              </li>
              <li>
                <Link to="/component_lib/cartsubtotal">Cart Subtotal</Link>
              </li>
              <li>
                <Link to="/component_lib/favorites">Favorites</Link>
              </li>
              <li>
                <Link to="/component_lib/favoritessearched">
                  Favorites Searched
                </Link>
              </li>
              <li>
                <Link to="/component_lib/sellercarouselbanner">
                  Seller Carousel Banner
                </Link>
              </li>
              <li>
                <Link to="/component_lib/sellerheader">Seller Header</Link>
              </li>
              <li>
                <Link to="/component_lib/purposecarousel">
                  Purpose Carousel
                </Link>
              </li>
              <li>
                <Link to="/component_lib/featuredproductsandspecials">
                  Featured Products and Specials
                </Link>
              </li>
              <li>
                <Link to="/component_lib/inaction">In Action</Link>
              </li>
              <li>
                <Link to="/component_lib/sellersuccessstories">
                  Seller Success Stories
                </Link>
              </li>
              <li>
                <Link to="/component_lib/carouselproductview">
                  Carousel Product View
                </Link>
              </li>
              <li>
                <Link to="/component_lib/carouselview">Carousel View</Link>
              </li>
              <li>
                <Link to="/component_lib/categoryview">Category View</Link>
              </li>
              <li>
                <Link to="/component_lib/purposeCategoryView">
                  Purpose Category View
                </Link>
              </li>
              <li>
                <Link to="/component_lib/relatedcategoriesandsearches">
                  Related Categories and Searches
                </Link>
              </li>
              <li>
                <Link to="/component_lib/ProductGalleryPreview">
                  Product Gallery Preview
                </Link>
              </li>
              <li>
                <Link to="/component_lib/PurchaseView">Purchase View</Link>
              </li>
              <li>
                <Link to="/component_lib/mypurpose">My Purpose</Link>
              </li>
              <li>
                <Link to="/component_lib/OpenAShop">Open A Shop</Link>
              </li>
              <li>
                <Link to="/component_lib/ManagementMenu">Management Menu</Link>
              </li>
              <li>
                <Link to="/component_lib/sellerstatschart">
                  Seller Stats Chart
                </Link>
              </li>
              <li>
                <Link to="/component_lib/orderNumberTable">
                  Order Number Table
                </Link>
              </li>
              <li>
                <Link to="/component_lib/notificationTable">
                  Notification Table
                </Link>
              </li>
            </ul>
          </nav>

          {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          <Switch>
            <Route path="/component_lib/blog">
              <Blog blogPosts={mockBlogData} />
            </Route>
            <Route path="/component_lib/dropdownbutton">
              <DropDownButton />
            </Route>
            <Route path="/component_lib/siteheader">
              <SiteHeader />
            </Route>
            <Route path="/component_lib/socialmedia">
              <SocialMedia />
            </Route>
            <Route path="/component_lib/footer">
              <Footer />
            </Route>
            <Route path="/component_lib/legalfooter">
              <LegalFooter />
            </Route>
            <Route path="/component_lib/galleryview">
              <GalleryView
                productPages={[
                  mockData,
                  mockData2,
                  mockData,
                  mockData2,
                  mockData,
                  mockData2,
                ]}
              />
            </Route>
            <Route path="/component_lib/reviews">
              <Reviews />
            </Route>
            <Route path="/component_lib/relatedpurchases">
              <RelatedPurchases related={mockRelatedPurchasesData} />
            </Route>
            <Route path="/component_lib/otherinterests">
              <OtherInterests otherInterests={MockOtherInterestsData} />
            </Route>
            <Route path="/component_lib/categorymenu">
              <CategoryMenu
                categoryData={categoryMenuData}
                expandedCategory={categoryMenuData.categories[4].category}
              />
            </Route>
            <Route path="/component_lib/keepup">
              <KeepUp blogPosts={mockBlogData} />
            </Route>
            <Route path="/component_lib/signin">
              <SignIn />
            </Route>
            <Route path="/component_lib/mypurpose">
              <MyPurpose myPurposes={myPurposeData} />
            </Route>
            <Route path="/component_lib/toolsandtips">
              <ToolsAndTips toolsAndTipsData={toolsAndTipsData} />
            </Route>
            <Route path="/component_lib/marketingandsales">
              <MarketingAndSales
                marketingAndSalesData={marketingAndSalesData}
              />
            </Route>
            <Route path="/component_lib/productphotoguideline">
              <ProductPhotoGuideline
                productPhotoGuidelineData={productPhotoGuidelineData}
              />
            </Route>
            <Route path="/component_lib/forum">
              <Forum forumData={forumData} />
            </Route>
            <Route path="/component_lib/addedtocart">
              <AddedToCart addedToCartData={addedToCartData} />
            </Route>
            <Route path="/component_lib/cartsubtotal">
              <CartSubtotal cartSubtotalData={cartSubtotalData} />
            </Route>
            <Route path="/component_lib/favorites">
              <Favorites />
            </Route>
            <Route path="/component_lib/favoritessearched">
              <FavoritesSearched favoritesSearchedData={mockData} />
            </Route>
            <Route path="/component_lib/sellercarouselbanner">
              <SellerCarouselBanner
                sellerCarouselBannerData={sellerCarouselBannerData}
              />
            </Route>
            <Route path="/component_lib/sellerheader">
              <SellerHeader />
            </Route>
            <Route path="/component_lib/purposecarousel">
              <PurposeCarousel />
            </Route>
            <Route path="/component_lib/featuredproductsandspecials">
              <FeaturedProductsAndSpecials />
            </Route>
            <Route path="/component_lib/inaction">
              <InAction />
            </Route>
            <Route path="/component_lib/sellersuccessstories">
              <SellerSuccessStories />
            </Route>
            <Route path="/component_lib/carouselproductview">
              <CarouselProductView data={mockData} />
            </Route>
            <Route path="/component_lib/carouselview">
              <CarouselView data={mockData} />
            </Route>
            <Route path="/component_lib/categoryview">
              <CategoryView data={mockData} />
            </Route>
            <Route path="/component_lib/purposeCategoryView">
              <PurposeCategoryView data={mockData} />
            </Route>
            <Route path="/component_lib/relatedcategoriesandsearches">
              <RelatedCategoriesAndSearches data={mockData} />
            </Route>
            <Route path="/component_lib/ProductGalleryPreview">
              <ProductGalleryPreview data={mockData} />
            </Route>
            <Route path="/component_lib/PurchaseView">
              <PurchaseView data={mockData} />
            </Route>
            <Route path="/component_lib/OpenAShop">
              <OpenAShopStepper />
            </Route>
            <Route path="/component_lib/managementmenu">
              <ManagementMenu />
            </Route>
            <Route path="/component_lib/sellerstatschart">
              <SellerStatsChart />
            </Route>
            <Route path="/component_lib/orderNumberTable">
              <OrderNumberTable />
            </Route>
            <Route path="/component_lib/notificationTable">
              <NotificationTable />
            </Route>
          </Switch>
        </div>
      </Router>
    </div>
  );
}
