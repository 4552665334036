import {
  Container,
  Grid,
  Typography,
  Button,
  Divider,
  Box,
  TextField,
  Modal,
  CircularProgress,
  outlinedInputClasses,
} from "@mui/material";
import { useState } from "react";
import useDocumentTitle from "../hooks/useDocumentTitle";
import { PurposeCategoryMasterLayout } from ".";
import { Auth } from "aws-amplify";
import { styled } from "@mui/material/styles";
import toast, { Toaster } from 'react-hot-toast';

const Header = styled(Typography)({
  color: "var(--mintGreenSolid)",
  marginTop: 10,
  marginBottom: 10,
});

const BoxForm = styled(Box)({
  width: "100%",
  marginTop: 20,
  marginBottom: 20,
});

const InputLabel = styled(Typography)({
  marginBottom: 10,
});

const InputField = styled(TextField)({
  [`& .${outlinedInputClasses.root}`]: {
    borderRadius: 7,
    "& fieldset": {
      borderColor: "#000000",
    },
    "&:hover fieldset": {
      borderColor: "var(--mintGreenSolid)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--mintGreenSolid)",
    },
  },
  [`& .${outlinedInputClasses.input}`]: {
    paddingTop: 11.5,
    paddingBottom: 10,
  },
});

const RightAlignContainer = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
});

const StyledButton = styled(Button)({
  textTransform: "none",
  backgroundColor: "var(--mintGreenSolid)",
  width: 190,
  "&:hover": {
    backgroundColor: "var(--mintGreenSolid)",
  },
});

function ForgotPasswordLayout() {
  useDocumentTitle("Change or Forgot Password");
  const [loading, setLoading] = useState(false);
  const [forgotPasswordRequest, setForgotPasswordRequest] = useState(null);

  const handleForgotPasswordRequest = async (event) => {
    event.preventDefault();
    if (!loading) {
      setLoading(true)
      const data = new FormData(event.currentTarget);
      try {

        const username = data.get("username");
        if (!username || username.length < 1) {
          toast.error("Must have a username!");
          setLoading(false)
          return;
        }

        await Auth.forgotPassword(username)
        .then((data) => {
          toast((t) => (
            <span>
              {`If this username is correct, you will receive an email with a verification code to reset your password.`}
              {'\n'}
              <Grid container justifyContent="center">
                <Button onClick={() => toast.dismiss(t.id)}>
                  Dismiss
                </Button>
              </Grid>
            </span>
          ), {
            duration: 20000,
            position: 'bottom-center',
          });
          //alert('If this username is correct, you will receive an email with a verification code to reset your password.')
          setForgotPasswordRequest(true);
        })
        .catch((err) => console.log(err));
      } catch (error) {
        toast((t) => (
          <span>
            {error}
            {'\n'}
            <Grid container justifyContent="center">
              <Button onClick={() => toast.dismiss(t.id)}>
                Dismiss
              </Button>
            </Grid>
          </span>
        ), {
          duration: 20000,
          position: 'bottom-center',
        });
      }
      setLoading(false)
    }
  };

  const handleForgotPasswordReset = async (event) => {
    event.preventDefault();
    if (!loading) {
      setLoading(true)
      const data = new FormData(event.currentTarget);
      try {
        const username = data.get("username");
        if (!username || username.length < 1) {
          toast.error("Must have a username!");
          setLoading(false)
          return;
        }
        if (data.get("password") !== data.get("password2")) {
          toast.error("Passwords do not match!");
          setLoading(false)
          return;
        }
        const code = data.get("code");
        if (!code || code.length < 1) {
          toast.error("Must have a verification code!");
          setLoading(false)
          return;
        }
        
        await Auth.forgotPasswordSubmit(username, code, data.get("password"))
        .then((data) => {
          toast.success('Successfully reset password!')
          window.location.href = "/login?afterPasswordReset=true";
        })
        .catch((err) => console.log(err));
      } catch (error) {
        toast.error(`${error}`);
      }
      setLoading(false)
    }
  };

  return (
    <PurposeCategoryMasterLayout>
      <Modal open={loading} sx={{ overflow: "scroll" }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: 2,
            bgcolor: "background.paper",
            p: 4,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress sx={{ color: "var(--coral)" }} size={150} />
        </Box>
      </Modal>
      <Toaster 
        toastOptions={{
          className: '',
          style: {
            border: '1px solid #713200',
            padding: '16px',
            color: '#713200',
          },
        }}
      />
      <Divider />
      <Container maxWidth="xl">
        <Header align="center" variant="h3" className={"roboto-bold"}>
          Change or Forgot Password
        </Header>
      </Container>
      <Container component="main" maxWidth="md">
        <Box
          sx={{
            marginTop: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <BoxForm 
            component="form"
            noValidate
            onSubmit={(e) => {
              if (forgotPasswordRequest == null) {
                handleForgotPasswordRequest(e);
              } else {
                handleForgotPasswordReset(e);
              }
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <InputLabel className={"roboto-medium"}>Username</InputLabel>
                <InputField
                  required
                  fullWidth
                  id="username"
                  name="username"
                  autoComplete="username"
                />
              </Grid>
              {forgotPasswordRequest && 
              <>
                <Grid item xs={12} sm={12}>
                  <InputLabel className={"roboto-medium"}>Verification Code</InputLabel>
                  <InputField
                    required
                    fullWidth
                    id="code"
                    name="code"
                    autoComplete="code"
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel className={"roboto-medium"}>Password</InputLabel>
                  <InputField
                    required
                    fullWidth
                    name="password"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                  />
                  <Typography sx={{typography: 'body2'}} className={"roboto-regular"}>
                    Password must include at least: 8 characters, 1 uppercase letter, 1 lowercase letter, and 1 special character
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <InputLabel className={"roboto-medium"}>Re-type Password</InputLabel>
                  <InputField
                    required
                    fullWidth
                    name="password2"
                    type="password"
                    id="password2"
                    autoComplete="new-password2"
                  />
                  <Typography sx={{typography: 'body2'}} className={"roboto-regular"}>
                    Please Re-type same Password as above
                  </Typography>
                </Grid>
              </>
              }
            </Grid>
            <RightAlignContainer>
              <StyledButton
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                <Typography className={"roboto-regular"}>
                  {forgotPasswordRequest == null ? `Submit Password Change Request` : `Reset Password`}
                </Typography>
              </StyledButton>
            </RightAlignContainer>
          </BoxForm>
        </Box>
      </Container>
    </PurposeCategoryMasterLayout>
  );
}

export default ForgotPasswordLayout;
