import { useEffect } from 'react';

export default function useTidioScript() {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = "//code.tidio.co/jbxvcm2gmgeoo0gnvn5epgzbbkfphqab.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);
};