import React from "react";
import { Route, Switch } from "react-router-dom";
import {
  AboutUsLayout,
  BlogLayout,
  ContactLayout,
  FAQLayout,
  HowItWorksLayout,
  OurImpactLayout,
  PartnershipLayout,
  PressLayout,
  PurposerGiftCardsLayout,
  ReturnPolicyLayout,
  SellerFAQLayout,
  SellerSupportLayout,
  ShippingAndReturnsFAQLayout,
  ManagementMasterLayout,
  MasterLayout,
  SellerDashboardLayout,
  SellerShopInformationLayout,
  SellerSignUpLayout,
  CartLayout,
  CategoryLayout,
  MyAccountLayout,
  ProductLayout,
  PurposeLayout,
  SellerStoreLayout,
  HomepageLayout,
  SignInLayout,
  SellOnPurposerLayout,
  SignUpLayout,
  AddListingLayout,
  AddBlogLayout,
  EditBlogLayout,
  CurrentListingsLayout,
  EditListingLayout,
  SellerAddShopLayout,
  OrdersAndShippingLayout,
  OrderReturnsLayout,
  MessagesLayout,
  ShopGallery,
  BlogArticleLayout,
  ShopStatsLayout,
  FinancesLayout,
  SalesAndCouponsLayout,
  ShopInventoryLayout,
  ShopReviewsLayout,
  ShopAdsLayout,
  OrderPlacedLayout,
  LoyaltyProgramLayout,
  TermsOfUseLayout,
  PrivacyLayout,
  InternetBasedAdsLayout,
  CareersLayout,
  AdminPortalManagementMaster,
  CurrentBlogLayout,
  StoreApproval,
  ReviewsLayout,
  CustomerOrdersLayout,
  ShopSettingsLayout
} from "./layouts";

// import AboutUsLayout from "./layouts/InformationalLayouts/AboutUsLayout";

// import HomepageLayout from "./layouts/HomepageLayout";
// import SignInLayout from "./layouts/SignInLayout";
// import MyAccountLayout from "./layouts/ShopperLayouts/MyAccountLayout";
// import FAQLayout from "./layouts/FAQLayout";
// import ContactLayout from "./layouts/InformationalLayouts/ContactLayout";
// import HowItWorksLayout from "./layouts/InformationalLayouts/HowItWorksLayout";
// import SellerSupportLayout from "./layouts/InformationalLayouts/SellerSupportLayout";
// import CategoryLayout from "./layouts/ShopperLayouts/CategoryLayout";
// import PurposeLayout from "./layouts/ShopperLayouts/PurposeLayout";
// import SellerStore from "./layouts/ShopperLayouts/SellerStoreLayout";

import LayoutsPage from "./components/LayoutsPage";
import SiteHeader from "./components/SiteHeader";
import Blog from "./components/Blog";
// import Forum from "./components/Forum";
import ComponentsPage from "./components/ComponentsPage";
import BestSellersLayout from "./layouts/ShopperLayouts/BestSellersLayout";
import SearchPageLayout from "./layouts/ShopperLayouts/SearchPageLayout";
import SearchSellerByCountryPageLayout from "./layouts/ShopperLayouts/SearchSellerByCountryPageLayout";
import ForgotPasswordLayout from "./layouts/ForgotPasswordLayout";
import MultipleCategoriesLayout from "./layouts/ShopperLayouts/MultipleCategoriesLayout";
import SalesAndCouponsAdminLayout from "./layouts/AdminLayouts/SalesAndCouponsAdminLayout";
// import FathersDayBlog from "./components/BlogLayoutFathersDay";
import BlogLayoutFathersDay from "./layouts/InformationalLayouts/BlogLayoutFathersDay";
import AddPictureLayout from "./layouts/AdminLayouts/AddPictureLayout";
import CoverPhotoUpdaterLayout from "./layouts/AdminLayouts/CoverPhotoUpdaterLayout";
import PressReleaseUpdaterLayout from "./layouts/AdminLayouts/PressReleaseUpdaterLayout";
import ProductsUnderPriceLayout from "./layouts/ShopperLayouts/ProductsUnderPriceLayout";
// import TestImageUpload from "./layouts/InformationalLayouts/TestImageUpload";
// import MasterLayout from "./layouts/MasterLayout";
// import SellerFAQLayout from "./layouts/InformationalLayouts/SellerFAQLayout";
// import ProductLayout from "./layouts/ShopperLayouts/ProductLayout";
// import BlogLayout from "./layouts/BlogLayout";
// import PartnershipLayout from "./layouts/InformationalLayouts/PartnershipLayout";
// import ShippingAndReturnsFAQLayout from "./layouts/InformationalLayouts/ShippingAndReturnsFAQLayout";

// import PurposerGiftCardsLayout from "./layouts/InformationalLayouts/PurposerGiftCardsLayout";
// import OurImpactLayout from "./layouts/InformationalLayouts/OurImpactLayout";
// import CartLayout from "./layouts/ShopperLayouts/CartLayout";
// import ManagementMasterLayout from "./layouts/MasterLayouts/ManagementMasterLayout";

//GUIDE: https://www.ryanjyost.com/react-routing/

const ROUTES = [
  { path: "/", key: "ROOT", exact: true, component: () => <HomepageLayout /> },
  {
    path: "/app",
    key: "APP",
    component: RenderRoutes,
    routes: [
      {
        path: "/app",
        key: "APP_ROOT",
        exact: true,
        component: () => <h1>App Index</h1>,
      },
      {
        path: "/app/page",
        key: "APP_PAGE",
        exact: true,
        component: () => <h1>App Page</h1>,
      },
    ],
  },
  {
    path: "/home",
    key: "home",
    exact: true,
    component: HomepageLayout,
  },
  {
    path: "/login",
    key: "login",
    component: RenderRoutes,
    routes: [
      {
        path: "/login",
        key: "login_root",
        exact: true,
        component: () => <SignInLayout />,
      },
      {
        path: "/login/reset",
        key: "login_reset",
        exact: true,
        component: () => <ForgotPasswordLayout />,
      },
    ],
  },
  {
    path: "/signup",
    key: "signup",
    exact: true,
    component: SignUpLayout,
  },
  {
    path: "/acct",
    key: "acct",
    exact: true,
    component: MyAccountLayout,
  },
  {
    path: "/faq",
    key: "faq",
    component: RenderRoutes,
    routes: [
      {
        path: "/faq",
        key: "faq_root",
        exact: true,
        component: () => <FAQLayout />,
      },
      {
        path: "/faq/seller",
        key: "faq_seller",
        exact: true,
        component: () => <SellerFAQLayout />,
      },
      {
        path: "/faq/shippingandreturns",
        key: "faq_shippingandreturns",
        exact: true,
        component: () => <ShippingAndReturnsFAQLayout />,
      },
      {
        path: "/faq/returns",
        key: "faq_return_policy",
        exact: true,
        component: () => <ReturnPolicyLayout />,
      },
    ],
  },
  {
    path: "/contact",
    key: "contact",
    exact: true,
    component: () => <ContactLayout />,
  },
  {
    path: "/about",
    key: "about",
    exact: true,
    component: () => <AboutUsLayout />,
  },
  {
    path: "/impact",
    key: "impact",
    exact: true,
    component: () => <OurImpactLayout />,
  },
  // {
  //   path: "/testImageUpload",
  //   key: "testImageUpload",
  //   exact: true,
  //   component: () => <TestImageUpload />,
  // },
  {
    path: "/termsofuse",
    key: "terms_of_use",
    exact: true,
    component: () => <TermsOfUseLayout/>,
  },
  {
    path: "/privacypolicy",
    key: "privacy",
    exact: true,
    component: () => <PrivacyLayout/>,
  },
  {
    path: "/internetbasedads",
    key: "internet_based_ads",
    exact: true,
    component: () => <InternetBasedAdsLayout/>,
  },
  {
    path: "/marketing",
    key: "marketing",
    exact: true,
    component: () => <h1>Marketing</h1>,
  },
  {
    path: "/careers",
    key: "careers",
    exact: true,
    component: () => <CareersLayout/>
  },
  {
    path: "/forum",
    key: "forum",
    component: RenderRoutes,
    routes: [
      {
        path: "/forum",
        key: "forum_root",
        exact: true,
        component: () => <h1>Forum Layout</h1>,
      },
      {
        path: "/forum/:id",
        key: "forum_post_id",
        component: () => <h1>Forum Post</h1>,
      },
    ],
  },
  {
    path: "/howitworks",
    key: "howitworks",
    exact: true,
    component: HowItWorksLayout,
  },
  {
    path: "/support",
    key: "support",
    component: RenderRoutes,
    routes: [
      {
        path: "/support",
        key: "support_root",
        exact: true,
        component: () => <h1>Support</h1>,
      },
      {
        path: "/support/seller",
        key: "support_seller",
        component: RenderRoutes,
        routes: [
          {
            path: "/support/seller",
            key: "support_seller_default",
            exact: true,
            component: () => <SellerSupportLayout />,
          },
          {
            path: "/support/seller/:info",
            key: "support_seller_info",
            component: () => <h1>Seller Support Info</h1>,
          },
        ],
      },
      {
        path: "/support/buyer",
        key: "support_buyer",
        exact: true,
        component: () => <h1>Buyer Support</h1>,
      },
    ],
  },
  {
    path: "/press",
    key: "press",
    component: RenderRoutes,
    routes: [
      {
        path: "/press",
        key: "press_root",
        exact: true,
        component: () => <PressLayout />,
      },
    ],
  },
  {
    path: "/shop",
    key: "shop",
    component: RenderRoutes,
    routes: [
      {
        path: "/shop",
        key: "shop_root",
        exact: true,
        component: () => <h1>Shop root</h1>,
      },
      {
        path: "/shop/loyaltyprogram",
        key: "shop_loyalty",
        exact: true,
        component: () => <LoyaltyProgramLayout />,
      },
      {
        path: "/shop/category",
        key: "shop_category",
        component: RenderRoutes,
        routes: [
          {
            path: "/shop/category",
            key: "shop_category_default",
            exact: true,
            component: () => <CategoryLayout />,
          },
          {
            path: "/shop/category/purposer/PurposerGiftCards",
            key: "shop_category_Purposer_Gift_Cards",
            exact: true,
            component: () => <PurposerGiftCardsLayout />,
          },
          {
            path: "/shop/category/:id",
            key: "shop_category_id",
            component: RenderRoutes,
            routes: [
              {
                path: "/shop/category/:id/:categoryName?",
                key: "shop_category_id_default",
                exact: true,
                component: () => <CategoryLayout />,
              },
              {
                path: "/shop/category/:id/:categoryName?/:subcategoryId/:subcategoryName?",
                key: "shop_subcategory_id",
                component: () => <CategoryLayout />,
              },
            ],
          },
        ],
      },
      {
        path: "/shop/categories",
        key: "shop_categories",
        component: RenderRoutes,
        routes: [
          {
            path: "/shop/categories",
            key: "shop_categories_default",
            exact: true,
            component: () => <MultipleCategoriesLayout />,
          },
          {
            path: "/shop/categories/:categoryIds",
            key: "shop_categories_ids",
            component: RenderRoutes,
            routes: [
              {
                path: "/shop/categories/:categoryIds",
                key: "shop_categories_ids_default",
                exact: true,
                component: () => <MultipleCategoriesLayout />,
              },
              {
                path: "/shop/categories/:categoryIds/:title",
                key: "shop_categories_ids_and_title",
                component: () => <MultipleCategoriesLayout />,
              },
            ],
          },
        ],
      },
      {
        path: "/shop/purpose",
        key: "shop_purpose",
        component: RenderRoutes,
        routes: [
          {
            path: "/shop/purpose",
            key: "shop_purpose_default",
            exact: true,
            component: () => <PurposeLayout />,
          },
          {
            path: "/shop/purpose/:id/:purposeName?",
            key: "shop_purpose_id",
            exact: true,
            component: () => <PurposeLayout />,
          },
          {
            path: "/shop/purpose/:id/:purposeName?/:subpurposeId/:subpurposeName?",
            key: "shop_subpurpose_id",
            component: () => <PurposeLayout />,
          },
        ],
      },
      {
        path: "/shop/productsByPrice",
        key: "shop_products_by_price",
        component: RenderRoutes,
        routes: [
          {
            path: "/shop/productsByPrice/:lessThanPrice/:title?",
            key: "shop_products_by_price_number",
            exact: true,
            component: () => <ProductsUnderPriceLayout />,
          },
        ],
      },
      {
        path: "/shop/product/:id/:name?",
        key: "shop_product_id",
        component: () => <ProductLayout />,
      },
      {
        path: "/shop/seller",
        key: "shop_seller",
        component: RenderRoutes,
        routes: [
          {
            path: "/shop/seller",
            key: "shop_seller_default",
            exact: true.valueOf,
            component: () => <h1>Shop Seller Default</h1>,
          },
          {
            path: "/shop/seller/:id/:name?",
            key: "shop_seller_id",
            component: () => <SellerStoreLayout />,
          },
        ],
      },
      {
        path: "/shop/country",
        key: "shop_country",
        component: RenderRoutes,
        routes: [
          {
            path: "/shop/country",
            key: "shop_country_default",
            exact: true,
            component: () => <h1>Shop Country Default</h1>,
          },
          {
            path: "/shop/country/:id",
            key: "shop_country_id",
            component: () => <h1>Shop Country Id</h1>,
          },
        ],
      },
      {
        path: "/shop/popular",
        key: "shop_popular",
        component: () => <BestSellersLayout />,
      },
    ],
  },
  {
    path: "/portal",
    key: "portal",
    component: RenderRoutes,
    routes: [
      {
        path: "/portal/seller",
        key: "portal_seller",
        component: () => <h1>Portal Seller</h1>,
      },
    ],
  },
  {
    path: "/chat",
    key: "chat",
    component: () => <h1>Live Chat</h1>,
  },
  {
    path: "/orderPlaced",
    key: "order_Placed",
    component: () => <OrderPlacedLayout />,
  },
  {
    path: "/blog",
    key: "blog",
    component: RenderRoutes,
    routes: [
      {
        path: "/blog",
        key: "blog_default",
        exact: true,
        component: () => <BlogLayout />,
      },
      {
        path: "/blog/seller",
        key: "blog_seller",
        component: () => <h1>Blog Seller</h1>,
      },
      {
        path: "/blog/fathers-day",
        key: "blog_seller",
        component: () => <BlogLayoutFathersDay />,
      },
      {
        path: "/blog/:id",
        key: "blog_id",
        component: () => <BlogArticleLayout />,
      },
    ],
  },
  {
    path: "/discussion",
    key: "discussion",
    component: RenderRoutes,
    routes: [
      {
        path: "/discussion",
        key: "discussion_default",
        exact: true,
        component: () => <h1>Discussion</h1>,
      },
      {
        path: "/discussion/:id",
        key: "discussion_id",
        component: () => <h1>Discussion Id</h1>,
      },
    ],
  },
  // {
  //   path: "/blog",
  //   key: "blog",
  //   component: RenderRoutes,
  //   routes: [
  //     {
  //       path: "/blog",
  //       key: "blog_root",
  //       exact: true,
  //       component: () => <h1>Blog Homepage</h1>,
  //     },
  //     {
  //       path: "/blog/special/:theme",
  //       key: "blog__special_post_id",
  //       component: () => <FathersDayBlog />,
  //     },
  //     {
  //       path: "/blog/:id",
  //       key: "blog_post_id",
  //       component: () => <Blog />,
  //     },
  //   ],
  // },
  {
    path: "/adminportal",
    key: "adminportal",
    component: RenderRoutes,
    routes: [
      {
        path: "/adminportal/storeapproval",
        key: "admin_store_approval",
        exact: true,
        component: () => <StoreApproval/>,
      },
      {
        path: "/adminportal/reviews",
        key: "admin_reviews",
        exact: true,
        component: () => <ReviewsLayout/>,
      },
      {
        path: "/adminportal/coupons",
        key: "admin_reviews",
        exact: true,
        component: () => <SalesAndCouponsAdminLayout/>,
      },
      {
        path: "/adminportal/currentblogs",
        key: "admin_current_blogs",
        exact: true,
        component: () => <CurrentBlogLayout/>,
      },
      {
        path: "/adminportal/addblog",
        key: "admin_add_blogs",
        exact: true,
        component: () => <AddBlogLayout/>,
      },
      {
        path: "/adminportal/addpicture",
        key: "admin_add_pictures",
        exact: true,
        component: () => <AddPictureLayout/>,
      },
      {
          path: "/adminportal/editBlog/:blogID",
          key: "admin_edit_blog",
          exact: true,
          component: () => <EditBlogLayout/>,
      },
      {
        path: "/adminportal/customerorders",
        key: "admin_customer_orders",
        exact: "true",
        component: () => <CustomerOrdersLayout/>
      },
      {
        path: "/adminportal/coverphotoupdater",
        key: "admin_customer_orders",
        exact: "true",
        component: () => <CoverPhotoUpdaterLayout/>
      },
      {
        path: "/adminportal/pressreleaseupdater",
        key: "admin_customer_orders",
        exact: "true",
        component: () => <PressReleaseUpdaterLayout/>
      }
    ],
  },
  {
    path: "/seller",
    key: "seller",
    component: RenderRoutes,
    routes: [
      {
        path: "/seller",
        key: "seller_root",
        exact: true,
        component: () => <SellOnPurposerLayout />,
      },
      {
        path: "/seller/signup",
        key: "seller_signup",
        exact: true,
        component: () => <SellerSignUpLayout />,
      },
      {
        path: "/seller/:id",
        key: "seller_id",
        exact: true,
        component: () => <h1>Seller Individual Page</h1>,
      },
      // {
      //   path: "/seller/:id/admin",
      //   key: "seller_id_admin",
      //   exact: true,
      //   component: () => <SellerStoreLayout />,
      // },
      // {
      //   path: "/seller/:id/dashboard",
      //   key: "seller_dashboard",
      //   exact: true,
      //   component: () => <SellerDashboardLayout />,
      // },
      {
        path: "/seller/:id/stats",
        key: "seller_stats",
        exact: true,
        component: () => <ShopStatsLayout />,
      },
      {
        path: "/seller/:id/inventory",
        key: "seller_inventory",
        exact: true,
        component: () => <ShopInventoryLayout />,
      },
      {
        path: "/seller/:id/reviews",
        key: "seller_reviews",
        exact: true,
        component: () => <ShopReviewsLayout />,
      },
      {
        path: "/seller/:id/settings",
        key: "seller_store_settings",
        exact: true,
        component: () => <ShopSettingsLayout />,
      },
      {
        path: "/seller/:id/finances",
        key: "seller_finances",
        exact: true,
        component: () => <FinancesLayout />,
      },
      {
        path: "/seller/:id/addShop",
        key: "seller_add_shop",
        exact: true,
        component: () => <SellerAddShopLayout />,
      },
      {
        path: "/seller/:id/shopInformation",
        key: "shop_information",
        exact: true,
        component: () => <SellerShopInformationLayout />,
      },
      {
        path: "/seller/:id/listings",
        key: "current_listings",
        exact: true,
        component: () => <CurrentListingsLayout />,
      },
      {
        path: "/seller/:id/addListing",
        key: "seller_add_listing",
        exact: true,
        component: () => <AddListingLayout />,
      },
      {
        path: "/seller/:id/:listing/clone",
        key: "seller_add_listing",
        exact: true,
        component: () => <AddListingLayout />,
      },
      // {
      //   path: "/seller/:id/addBlog",
      //   key: "seller_add_blog",
      //   exact: true,
      //   component: () => <AddBlogLayout />,
      // },
      // {
      //   path: "/seller/:id/editBlog/:blogID",
      //   key: "seller_edit_blog",
      //   exact: true,
      //   component: () => <EditBlogLayout />,
      // },
      {
        path: "/seller/:id/:listing/edit",
        key: "seller_add_listing",
        exact: true,
        component: () => <EditListingLayout />,
      },
      {
        path: "/seller/:id/orders",
        key: "seller_orders_and_shipping",
        exact: true,
        component: () => <OrdersAndShippingLayout />,
      },
      {
        path: "/seller/:id/returns",
        key: "seller_order_returns",
        exact: true,
        component: () => <OrderReturnsLayout />,
      },
      // {
      //   path: "/seller/:id/messages",
      //   key: "seller_messages",
      //   exact: true,
      //   component: () => <MessagesLayout />,
      // },
      // {
      //   path: "/seller/:id/shopGallery",
      //   key: "seller_shop_gallery",
      //   exact: true,
      //   component: () => <ShopGallery />,
      // },
      {
        path: "/seller/:id/salesAndCoupons",
        key: "seller_sales_and_coupons",
        exact: true,
        component: () => <SalesAndCouponsLayout />,
      },
      // {
      //   path: "/seller/:id/ads",
      //   key: "seller_ads",
      //   exact: true,
      //   component: () => <ShopAdsLayout />,
      // },
    ],
  },
  {
    path: "/partnership",
    key: "partnership",
    exact: true,
    component: () => <PartnershipLayout />,
  },
  {
    path: "/cart",
    key: "cart",
    exact: true,
    component: () => <CartLayout />,
  },
  {
    path: "/search",
    key: "search",
    exact: true,
    component: () => <SearchPageLayout />,
  },
  {
    path: "/searchByCountry",
    key: "searchByCountry",
    exact: true,
    component: () => <SearchSellerByCountryPageLayout />,
  },
  {
    path: "/debug",
    key: "debug",
    component: RenderRoutes,
    routes: [
      {
        path: "/debug/components",
        key: "debug_components",
        component: RenderRoutes,
        routes: [
          {
            path: "/debug/components",
            key: "debug_components_root",
            exact: true,
            component: ComponentsPage,
          },
          {
            path: "/debug/components/header",
            key: "debug_components_header",
            exact: true,
            component: SiteHeader,
          },
        ],
      },
      {
        path: "/debug/layouts",
        key: "layouts",
        component: RenderRoutes,
        routes: [
          {
            path: "/debug/layouts",
            key: "debug_layouts_root",
            exact: true,
            component: LayoutsPage,
          },
          {
            path: "/debug/layouts/masterLayout",
            key: "debug_master_layout",
            exact: true,
            component: MasterLayout,
          },
          {
            path: "/debug/layouts/managementmasterLayout",
            key: "debug_management_master_layout",
            exact: true,
            component: ManagementMasterLayout,
          },
        ],
      },
    ],
  },
];

/**
 * Render a route with potential sub routes
 * https://reacttraining.com/react-router/web/example/route-config
 */
function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) => <route.component {...props} routes={route.routes} />}
    />
  );
}

/**
 * Use this component for any new section of routes (any config object that has a "routes" property
 */
export function RenderRoutes({ routes }) {
  return (
    <Switch>
      {routes.map((route, i) => {
        return <RouteWithSubRoutes key={route.key} {...route} />;
      })}
      <Route component={() => <h1>404 Not Found</h1>} />
    </Switch>
  );
}

export default ROUTES;
