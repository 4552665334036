import { Container, Typography, Button, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { round } from "../util/utilities";

//Default Prop Values
import cartSubtotalDataDefault from "../json/cart_subtotal.json";

const useStyles = makeStyles({
  containerBackground: {
    backgroundColor: "var(--coral)",
    paddingTop: 25,
    paddingBottom: 25,
  },
  buttonGridItem: {
    textAlign: "right",
  },
  button: {
    backgroundColor: "var(--mintGreen)",
    color: "#FFFFFF",
    borderRadius: 10,
    textTransform: "none",
    width: "100%",
    height: 45,
    "&:hover": {
      backgroundColor: "var(--mintGreen)",
    },
  },
  text: {
    fontSize: "1.2rem",
  },
  subtotalText: {
    marginLeft: 20,
  },
});

function CartSubtotal(props) {
  const classes = useStyles();

  const { cartSubtotalData = cartSubtotalDataDefault } = props;

  return (
    <Container maxWidth="xl" className={classes.containerBackground}>
      <Grid container direction="row" alignItems="center">
        <Grid item xs>
          <Typography
            className={clsx(classes.text, classes.subtotalText)}
            display="inline"
          >
            Cart Subtotal ({cartSubtotalData.numItems} item
            {cartSubtotalData.numItems !== 1 ? "s" : ""}):&nbsp;
            <b>${round(cartSubtotalData.price, 2)}</b>
          </Typography>
        </Grid>
        <Grid item xs={4} md={3} lg={2} className={classes.buttonGridItem}>
          <Button className={classes.button}>
            <Typography className={classes.text}>
              <b>Checkout Now</b>
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

export default CartSubtotal;
