import { Typography, Grid, Button, Avatar } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React, { useRef } from "react";
import useElementWidth from "../hooks/useElementWidth";
import MyPurposeDownArrow from "../img/my_purpose_down_arrow.png";

const useStyles = makeStyles({
  header: {
    fontSize: "1.3rem",
    marginBottom: 45,
  },
  noPurposesText: {
    fontSize: "1.2rem",
  },
  bubbleGridItem: {
    maxWidth: "100%",
  },
  bubbleDiv: {
    maxWidth: 230,
    aspectRatio: 230 / 200,
  },
  bubbleAvatar: {
    width: "60%",
    height: "68%",
    maxWidth: "100%",
    backgroundColor: "#F2E9D8",
    marginBottom: 15,
  },
  bubbleText: {
    fontSize: "2.9em",
    color: "#000000",
  },
  arrowImg: {
    maxHeight: 100,
  },
  emailDiv: {
    width: "45%",
    margin: "auto",
    marginTop: 20,
  },
  emailMeButton: {
    textTransform: "none",
    color: "#000000",
    backgroundColor: "#4EAE88",
    marginTop: 15,
    borderRadius: 5,
    minWidth: 140,
    minHeight: 40,
    "&:hover": {
      backgroundColor: "#4EAE88",
    },
  },
});

const PurposeBubble = (props) => {
  let { bubbleText, description, classes, avatarRef, avatarWidth } = props;

  // Used to adjust text size to make sure it doesn't overflow if it is
  // too long by default
  let avatarRatio =
    bubbleText.length <= 5 ? 8.8625 : 8.8625 + 2 * (bubbleText.length - 5);

  return (
    <Grid item xs={6} align="center" className={classes.bubbleGridItem}>
      <div className={classes.bubbleDiv}>
        <Avatar
          className={classes.bubbleAvatar}
          ref={avatarRef}
          style={{ fontSize: avatarWidth / avatarRatio }}
        >
          <Typography className={classes.bubbleText}>{bubbleText}</Typography>
        </Avatar>
        <Typography>
          {description.prebold}
          <b>{description.bold}</b>
          {description.postbold}
        </Typography>
      </div>
    </Grid>
  );
};

function MyPurpose(props) {
  let classes = useStyles();

  let myPurposes = props.myPurposes;

  const avatarRef = useRef(null);
  const avatarWidth = useElementWidth(avatarRef);

  return (
    <>
      <Typography align="center" className={classes.header}>
        <b>Your Purposes &amp; Impact!</b>
      </Typography>
      {myPurposes ? (
        <>
          <Grid container direction="row" justifyContent="center">
            {myPurposes.map((purpose, index) => {
              if (index !== 0 && index % 2 === 0) {
                return (
                  <React.Fragment key={index}>
                    <Grid item xs={12} align="center">
                      <img
                        src={MyPurposeDownArrow}
                        alt=""
                        className={classes.arrowImg}
                      />
                    </Grid>
                    <PurposeBubble
                      bubbleText={purpose.bubbleText}
                      description={purpose.description}
                      classes={classes}
                      avatarRef={avatarRef}
                      avatarWidth={avatarWidth}
                    />
                  </React.Fragment>
                );
              } else {
                return (
                  <PurposeBubble
                    bubbleText={purpose.bubbleText}
                    description={purpose.description}
                    classes={classes}
                    avatarRef={avatarRef}
                    avatarWidth={avatarWidth}
                    key={index}
                  />
                );
              }
            })}
          </Grid>
          <div align="center" className={classes.emailDiv}>
            <Typography align="center">
              <b>Want an email summary of your purposes and impact so far?</b>
            </Typography>
            <Button className={classes.emailMeButton}>
              <Typography>
                <b>
                  <i>Yes Email Me!</i>
                </b>
              </Typography>
            </Button>
          </div>
        </>
      ) : (
        <Typography align="center" className={classes.noPurposesText}>
          No Purposes Yet!
        </Typography>
      )}
    </>
  );
}

export default MyPurpose;
